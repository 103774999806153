import React from 'react'
import { useLangue } from '../../utils/hooks'
import './services.css'

function Services() {
  const { langue } = useLangue()

  return (
    <div className="service" langue={langue}>
      <div>
        <h1 className="service_part_1_h1">
          {langue === 'english'
            ? 'Our services'
            : langue === 'portuguais'
            ? 'Os nossos serviços'
            : 'Unsere Dienstleistungen'}
        </h1>
        <div className="service_part_1_h1_bottom"></div>
        <div className="service_part_2">
          <div style={{ display: 'flex' }}>
            <ion-icon name="home-outline"></ion-icon>
            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'All types of credit'
                  : langue === 'portuguais'
                  ? 'Todos os tipos de crédito'
                  : 'Alle Arten von Krediten'}
              </a>
              <p>
                {langue === 'english'
                  ? 'A financing offer adapted to all your business creation, university studies, travel, real estate acquisition or construction projects…'
                  : langue === 'portuguais'
                  ? 'Uma oferta de financiamento adaptada a todas as suas criações de negócios, estudos universitários, viagens, aquisições imobiliárias ou projetos de construção...'
                  : 'Ein Finanzierungsangebot, das sich an alle Ihre Unternehmensgründungen, Universitätsstudien, Reisen, Immobilienkäufe oder Bauprojekte anpasst...'}
              </p>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <ion-icon name="chatbubbles"></ion-icon>
            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'Quick response within an hour'
                  : langue === 'portuguais'
                  ? 'Resposta rápida em uma hora'
                  : 'Schnelle Antwort innerhalb einer Stunde'}
              </a>
              <p>
                {langue === 'english'
                  ? 'As financing experts, we are able to offer you different solutions and guide you towards the one best suited to your needs.'
                  : langue === 'portuguais'
                  ? 'Como especialistas em financiamento, somos capazes de oferecer-lhe diferentes soluções e orientá-lo para a mais adequada às suas necessidades.'
                  : 'Als Finanzierungsexperten sind wir in der Lage, Ihnen verschiedene Lösungen anzubieten und Sie zur für Ihre Bedürfnisse am besten geeigneten zu führen.'}
              </p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <ion-icon name="card"></ion-icon>
            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'No additional fees'
                  : langue === 'portuguais'
                  ? 'Sem taxas adicionais'
                  : 'Keine zusätzlichen Gebühren'}
              </a>
              <p>
                {langue === 'english'
                  ? 'We guarantee you the most competitive fees in the sector and will not charge you any additional fees for studying your file.'
                  : langue === 'portuguais'
                  ? 'Garantimos-lhe as taxas mais competitivas do setor e não lhe cobraremos quaisquer taxas adicionais para estudar o seu processo.'
                  : 'Wir garantieren Ihnen die wettbewerbsfähigsten Gebühren in der Branche und erheben keine zusätzlichen Gebühren für die Bearbeitung Ihrer Akte.'}
              </p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <ion-icon name="finger-print"></ion-icon>
            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'Guaranteed security'
                  : langue === 'portuguais'
                  ? 'Segurança garantida'
                  : 'Garantierte Sicherheit'}
              </a>
              <p>
                {langue === 'english'
                  ? 'All information that passes through our website is protected with end-to-end encryption.'
                  : langue === 'portuguais'
                  ? 'Todas as informações que passam pelo nosso site são protegidas com criptografia de ponta a ponta.'
                  : 'Alle Informationen, die unsere Website durchlaufen, sind mit End-to-End-Verschlüsselung geschützt.'}
              </p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <ion-icon name="sunny"></ion-icon>
            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'The best investment'
                  : langue === 'portuguais'
                  ? 'O melhor investimento'
                  : 'Die beste Investition'}
              </a>
              <p>
                {langue === 'english'
                  ? 'Our success depends on yours because we consider that our customers are our best investment.'
                  : langue === 'portuguais'
                  ? 'O nosso sucesso depende do seu, pois consideramos que os nossos clientes são o nosso melhor investimento.'
                  : 'Unser Erfolg hängt von Ihrem ab, weil wir unsere Kunden als unsere beste Investition betrachten.'}
              </p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <ion-icon name="trophy-outline"></ion-icon>
            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'Succeed and recommend us'
                  : langue === 'portuguais'
                  ? 'Tenha sucesso e recomende-nos'
                  : 'Erfolgreich sein und uns empfehlen'}
              </a>
              <p>
                {langue === 'english'
                  ? 'The best advertising is what our customers do. Dare to take the big step that will lead you to success and nourish our notoriety.'
                  : langue === 'portuguais'
                  ? 'A melhor publicidade é aquela que os nossos clientes fazem. Atreva-se a dar o grande passo que o levará ao sucesso e alimente a nossa notoriedade.'
                  : 'Die beste Werbung ist die, die unsere Kunden machen. Wagen Sie den großen Schritt, der Sie zum Erfolg führen wird und unsere Bekanntheit nährt.'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
