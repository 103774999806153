import React from 'react'
import { Link } from 'react-router-dom'
import './emailVErify.css'
import { useLangue } from '../../utils/hooks'

function EmailVerify() {
  const { langue } = useLangue()

  return (
    <div className="emailVerify_container" langue={langue}>
      <div className="emailVerify_sub_container">
        <div className="emailVerify_left">
          <h1 className="emailVerify-title-1">
            {langue === 'english'
              ? 'Asly Bank'
              : langue === 'portuguais'
              ? 'Banco Asly'
              : 'Asly Bank'}
          </h1>
          <h2 className="emailVerify-title-2">
            {langue === 'english'
              ? 'For your satisfaction'
              : langue === 'portuguais'
              ? 'Para sua satisfação'
              : 'Für Ihre Zufriedenheit'}
          </h2>
          <h3 className="emailVerify-title-3">
            {langue === 'english'
              ? 'Welcome to Asly Bank!'
              : langue === 'portuguais'
              ? 'Bem-vindo ao Banco Asly!'
              : 'Willkommen bei der Asly Bank!'}
          </h3>
          <p className="emailVerify-title-message">
            {langue === 'english'
              ? `We are delighted to welcome you among us. Your new account opens the doors to our banking services adapted to your needs. Explore our online platform, carry out your transactions securely, and benefit from exclusive advantages. Need help? Our team is here for you. Thank you for trusting Asly Bank. We are here to help you achieve your financial goals. Please check your spam if you do not receive the validation link for your account.`
              : langue === 'portuguais'
              ? `Estamos felizes em recebê-lo entre nós. Sua nova conta abre as portas para nossos serviços bancários adaptados às suas necessidades. Explore nossa plataforma online, realize suas transações com segurança e aproveite vantagens exclusivas. Precisa de ajuda? Nossa equipe está aqui para você. Obrigado por confiar no Banco Asly. Estamos aqui para ajudá-lo a alcançar seus objetivos financeiros. Verifique o seu spam se não receber a ligação de validação da sua conta.`
              : `Wir freuen uns, Sie bei uns begrüßen zu dürfen. Ihr neues Konto öffnet die Türen zu unseren Bankdienstleistungen, die auf Ihre Bedürfnisse zugeschnitten sind. Entdecken Sie unsere Online-Plattform, tätigen Sie Ihre Transaktionen sicher und profitieren Sie von exklusiven Vorteilen. Brauchen Sie Hilfe? Unser Team ist für Sie da. Vielen Dank, dass Sie der Asly Bank vertrauen. Wir sind hier, um Ihnen zu helfen, Ihre finanziellen Ziele zu erreichen. Bitte überprüfen Sie Ihren Spam-Ordner, wenn Sie den Bestätigungslink für Ihr Konto nicht erhalten haben.`}
          </p>
        </div>
        <div className="message_display">
          <h1>
            {langue === 'english'
              ? 'Verifying your email address'
              : langue === 'portuguais'
              ? 'Verificando seu endereço de e-mail'
              : 'Bestätige deine E-Mail-Adresse'}
          </h1>
          <Link className="link">
            {langue === 'english'
              ? 'Resend the verification email'
              : langue === 'portuguais'
              ? 'Reenviar o e-mail de verificação'
              : 'Bestätigungs-E-Mail erneut senden'}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default EmailVerify
