import React from 'react'
// bibliothèques de slides
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useLangue } from '../../utils/hooks'

import './chooseUs.css'

function WhyChooseUs() {
  const { langue } = useLangue()
  const settings = {
    dots: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
  }

  return (
    <div className="home_part_2">
      <h1 className="home_part_2_h1">
        {langue === 'english'
          ? 'Why choose us ?'
          : langue === 'portuguais'
          ? 'Por que nos escolher ?'
          : 'Warum uns wählen?'}
      </h1>
      <div className="home_part_2_h1_bottom"></div>
      <div className="slider_part">
        <div className="home_part_2_img_container">
          {/* <img src={WhyUs} alt="WhyUs" className="home_part_2_img" /> */}
        </div>
        <div className="slide_wrapper">
          <Slider {...settings}>
            <div className="slider_content">
              <h2 className="slider_content_h2">
                {langue === 'english'
                  ? 'Protecting your Finances'
                  : langue === 'portuguais'
                  ? 'Protegendo suas Finanças'
                  : 'Schutz Ihrer Finanzen'}
              </h2>
              <h3 className="slider_content_h3">
                {langue === 'english'
                  ? 'Robust Security and 24/7 Surveillance'
                  : langue === 'portuguais'
                  ? 'Segurança robusta e vigilância 24 horas por dia, 7 dias por semana'
                  : 'Robuste Sicherheit und 24/7 Überwachung'}
              </h3>
              <p className="slider_content_p">
                {langue === 'english'
                  ? "Enjoy a borderless banking experience. Access your accounts anytime, from anywhere in the world, with our easy-to-use platform. Whether you're at home, on the move or abroad, manage your finances with ease."
                  : langue === 'portuguais'
                  ? 'Desfrute de uma experiência bancária sem fronteiras. Acesse suas contas a qualquer momento, de qualquer lugar do mundo, com nossa plataforma fácil de usar. Esteja você em casa, em movimento ou no exterior, gerencie suas finanças com facilidade.'
                  : 'Genießen Sie eine grenzenlose Bankerfahrung. Greifen Sie jederzeit und von überall auf der Welt auf Ihre Konten zu, mit unserer benutzerfreundlichen Plattform. Egal ob zu Hause, unterwegs oder im Ausland, verwalten Sie Ihre Finanzen mit Leichtigkeit.'}
              </p>
            </div>
            <div className="slider_content">
              <h2 className="slider_content_h2">
                {langue === 'english'
                  ? 'The future of our Bank'
                  : langue === 'portuguais'
                  ? 'O futuro do nosso banco'
                  : 'Die Zukunft unserer Bank'}
              </h2>
              <h3 className="slider_content_h3">
                {langue === 'english'
                  ? 'Advanced Solutions and Smart Services'
                  : langue === 'portuguais'
                  ? 'Soluções avançadas e serviços inteligentes'
                  : 'Fortgeschrittene Lösungen und intelligente Dienstleistungen'}
              </h3>
              <p className="slider_content_p">
                {langue === 'english'
                  ? 'Discover innovation in every transaction. Our online banking offers advanced technology solutions such as financial analysis tools, user-friendly applications and integrated services, giving you a modern and efficiency-driven banking experience.'
                  : langue === 'portuguais'
                  ? 'Descubra a inovação em cada transação. Nosso banco online oferece soluções tecnológicas avançadas, como ferramentas de análise financeira, aplicativos fáceis de usar e serviços integrados, proporcionando uma experiência bancária moderna e orientada para a eficiência.'
                  : 'Entdecken Sie Innovation bei jeder Transaktion. Unser Online-Banking bietet fortschrittliche Technologielösungen wie Finanzanalysetools, benutzerfreundliche Anwendungen und integrierte Dienstleistungen, die Ihnen eine moderne und effizienzorientierte Bankenerfahrung bieten.'}
              </p>
            </div>
            <div className="slider_content">
              <h2 className="slider_content_h2">
                {langue === 'english'
                  ? 'Your Satisfaction, Our Commitment'
                  : langue === 'portuguais'
                  ? 'Sua Satisfação, Nosso Compromisso'
                  : 'Ihre Zufriedenheit, Unser Engagement'}
              </h2>
              <h3 className="slider_content_h3">
                {langue === 'english'
                  ? 'Responsive and Personalized Customer Support'
                  : langue === 'portuguais'
                  ? 'Suporte ao Cliente Responsivo e Personalizado'
                  : 'Reaktionsschneller und personalisierter Kundensupport'}
              </h3>
              <p className="slider_content_p">
                {langue === 'english'
                  ? 'We are committed to providing exceptional customer service. Our dedicated team is available to answer your questions, resolve your issues and support you every step of the way. We value every customer, and your satisfaction is at the heart of our mission.'
                  : langue === 'portuguais'
                  ? 'Estamos comprometidos em fornecer um serviço excepcional ao cliente. Nossa equipe dedicada está disponível para responder às suas perguntas, resolver seus problemas e apoiá-lo em cada etapa do caminho. Valorizamos cada cliente, e sua satisfação está no centro de nossa missão.'
                  : 'Wir verpflichten uns, außergewöhnlichen Kundenservice zu bieten. Unser engagiertes Team steht Ihnen zur Verfügung, um Ihre Fragen zu beantworten, Ihre Probleme zu lösen und Sie auf jedem Schritt des Weges zu unterstützen. Wir schätzen jeden Kunden, und Ihre Zufriedenheit steht im Mittelpunkt unserer Mission.'}
              </p>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default WhyChooseUs
