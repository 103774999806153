import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import Error from './components/Error'
import Home from './pages/Home'
import About from './pages/About'
import Insurance from './pages/Insurance'
import Team from './pages/Team'
import Services from './pages/Services'
import Contact from './pages/Contact'
// import LoanFrom from './pages/LoanForm'

import BankAccountInfo from './pages/BankAccount'
import Confidentiality from './pages/Confidentiality'
import LegalNotice from './pages/LegalNotice'

import Signup from './pages/Signup'
import Login from './pages/Login'

// private routes
import EmailVerify from './usersPages/EmailVerify'
import Profile from './usersPages/Profile'
import UpdateProfile from './usersPages/UpdateProfile'
import UserDashbord from './usersPages/UserDashbord'
import BankAccount from './usersPages/BankAccount'
import AccountData from './usersPages/AccountData'
import AccountOperation from './usersPages/AccountOperation'
import Loan from './usersPages/Loan'

import AdminDashbord from './adminPages/AdminDashbord'

import './utils/GlobalStyle.css'
import { LoginProvider } from './utils/context'

import { LangueProvider } from './utils/context'
import ScrollToTop from './utils/components/ScrollToTop'

import useIdleTimer from './useIdleTimer'

function App() {
  const checkTokenExpiration = () => {
    setInterval(() => {
      const tokenExpiration = parseInt(
        localStorage.getItem('tokenExpiration'),
        10
      )
      if (!isNaN(tokenExpiration)) {
        const currentTime = new Date().getTime()
        if (currentTime - tokenExpiration >= 2 * 60 * 60 * 1000) {
          // 2 heures en millisecondes
          // Le token a expiré, supprimer le token du stockage
          localStorage.removeItem('token')
          localStorage.removeItem('tokenExpiration')
          localStorage.removeItem('firstName')
          localStorage.removeItem('lastName')
        }
      }
    }, 60000) // Vérifie toutes les minutes
  }

  // Appeler cette fonction pour vérifier périodiquement si le token est expiré
  checkTokenExpiration()

  // handle auto logout after 30min

  const handleIdle = () => {
    // // Appelez votre API backend pour déconnecter l'utilisateur
    // fetch('/api/logout', {
    //   method: 'POST',
    //   credentials: 'include', // Assurez-vous d'envoyer les cookies avec la requête
    // }).then((response) => {
    //   if (response.ok) {
    //     // Redirigez l'utilisateur vers la page de connexion
    //     window.location.href = '/login'
    //   }
    // })
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token')
      localStorage.removeItem('tokenExpiration')
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      window.location.href = '/login'
    }
  }

  useIdleTimer(30 * 60 * 1000, handleIdle) // 30 minutes

  // end

  return (
    <Router>
      <LangueProvider>
        <LoginProvider>
          <Header />
          {/*start publics route */}
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/insurance" element={<Insurance />} />
            <Route path="/team" element={<Team />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="/loanAsking" element={<LoanFrom />} /> */}

            {/* Footer pages links */}
            <Route path="/bank_account_info" element={<BankAccountInfo />} />
            <Route path="/confidentiality" element={<Confidentiality />} />
            <Route path="/legal_notice" element={<LegalNotice />} />

            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            {/*End publics route */}

            {/*start private route */}

            <Route path="/account_validation" element={<EmailVerify />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/update_user_profile" element={<UpdateProfile />} />
            <Route path="/user_dashbord" element={<UserDashbord />} />

            <Route path="/bank_account" element={<BankAccount />}>
              <Route path="datas" element={<AccountData />} />
              <Route path="operations" element={<AccountOperation />} />
            </Route>

            <Route path="/loan" element={<Loan />} />

            {/*End private route */}

            {/*start admin route */}

            <Route path="/display_users" element={<AdminDashbord />} />

            {/*End admin route */}

            <Route path="*" element={<Error />} />
          </Routes>

          <Footer />
        </LoginProvider>
      </LangueProvider>
    </Router>
  )
}

export default App
