import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './login.css'
import { useLangue } from '../../utils/hooks'
import { Loader } from '../../utils/style/loader'

function Login() {
  const [isLoading, setIsLoading] = useState(false)
  const { langue } = useLangue()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [userId, setUserId] = useState('')
  const currentTime = new Date().getTime()

  const checkEmptyData = () => {
    if (password === '' || email === '') {
      setIsLoading(false)
      setError(
        langue === 'english'
          ? 'No empty fields!'
          : langue === 'portuguais'
          ? 'Nenhum campo vazio!'
          : 'Keine leeren Felder!'
      )
    } else if (password !== '' && email !== '') {
      setError('')

      const token = localStorage.getItem('token')

      if (!token) {
        setIsLoading(true)
        setError('')
        fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        })
          .then((response) => response.json())
          .then((user) => {
            if (user.token) {
              localStorage.setItem('token', user.token)
              localStorage.setItem('userRole', user.isAdmin)
              localStorage.setItem('firstName', user.firstName)
              localStorage.setItem('lastName', user.lastName)
              localStorage.setItem('tokenExpiration', currentTime)

              setIsLoading(false)
              setError('')
              setUserId(user.userId)
              window.location.href = '/user_dashbord'
            } else {
              setIsLoading(false)
              setError(
                langue === 'english'
                  ? user.message ||
                      'Login failed. Please check your credentials.'
                  : langue === 'portuguais'
                  ? 'Falha no login. Por favor, verifique suas credenciais.'
                  : 'Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldeinformationen.'
              )
            }
          })
          .catch((error) => {
            setIsLoading(false)
            setError(
              langue === 'english'
                ? 'An error occurred while logging in.'
                : langue === 'portuguais'
                ? 'Ocorreu um erro ao fazer login.'
                : 'Beim Anmelden ist ein Fehler aufgetreten.'
            )
          })
      } else {
        window.location.href = '/user_dashbord'
      }
    }
  }

  const connectUsers = () => {
    checkEmptyData()
  }

  return (
    <div className="loginContainer" langue={langue}>
      <div className="login_sub_container">
        <div className="login_left">
          <h1 className="login-title-1">Asly Bank</h1>
          <h2 className="login-title-2">
            {langue === 'english'
              ? 'For your satisfaction'
              : langue === 'portuguais'
              ? 'Para sua satisfação'
              : 'Zu Ihrer Zufriedenheit'}
          </h2>
          <h3 className="login-title-3">
            {langue === 'english'
              ? 'Welcome'
              : langue === 'portuguais'
              ? 'Bem-vindo'
              : 'Willkommen'}
          </h3>
          <p className="login-title-message">
            {langue === 'english'
              ? 'We are delighted to welcome you again! The protection of your personal data is of paramount importance to us, and we want to ensure the security of your information when browsing our platform.'
              : langue === 'portuguais'
              ? 'Estamos felizes em recebê-lo novamente! A proteção de seus dados pessoais é de suma importância para nós, e queremos garantir a segurança de suas informações ao navegar em nossa plataforma.'
              : 'Wir freuen uns, Sie wieder begrüßen zu dürfen! Der Schutz Ihrer persönlichen Daten ist uns sehr wichtig, und wir möchten die Sicherheit Ihrer Informationen beim Surfen auf unserer Plattform gewährleisten.'}
          </p>
        </div>
        <div className="login_form">
          <h1 className="login-title-4">
            {langue === 'english'
              ? 'Connection'
              : langue === 'portuguais'
              ? 'Conexão'
              : 'Anmelden'}
          </h1>
          <div>
            <label className="login-label">
              {langue === 'english'
                ? 'Email'
                : langue === 'portuguais'
                ? 'E-mail'
                : 'E-Mail'}
            </label>
            <input
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              className="login-input"
              type="email"
            />
          </div>
          <div>
            <label className="login-label">
              {langue === 'english'
                ? 'Password'
                : langue === 'portuguais'
                ? 'Senha'
                : 'Passwort'}
            </label>
            <input
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              className="login-input"
              type="password"
            />
          </div>
          <div>
            <input
              className=""
              type="checkbox"
              defaultChecked
              style={{
                marginRight: '5px ',
                accentColor: 'rgba(0, 0, 255, 0.656)',
              }}
            />
            {langue === 'english'
              ? 'Remember me'
              : langue === 'portuguais'
              ? 'Lembrar-me'
              : 'Angemeldet bleiben'}
          </div>
          <h3 style={{ color: 'red' }}> {error} </h3>

          <Link
            onClick={(e) => {
              e.preventDefault()
              connectUsers()
            }}
            to=""
            className="connect_me login_btn"
          >
            {isLoading ? (
              <Loader />
            ) : langue === 'english' ? (
              'Login'
            ) : langue === 'portuguais' ? (
              'Conexão'
            ) : (
              'Anmelden'
            )}
          </Link>

          <div className="forgot_password">
            <a href="#notAvailable">
              {langue === 'english'
                ? 'Forgot your password ?'
                : langue === 'portuguais'
                ? 'Esqueceu sua senha ?'
                : 'Passwort vergessen ?'}
            </a>

            <Link to="/signup">
              {langue === 'english'
                ? 'Create an account !'
                : langue === 'portuguais'
                ? 'Criar uma conta !'
                : 'Ein Konto erstellen !'}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
