import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Loader } from '../../utils/style/loader'

import { LoginContext } from '../../utils/context'
import { useLangue } from '../../utils/hooks'

import './loan.css'

function Loan() {
  const { langue } = useLangue()
  const token = localStorage.getItem('token')

  const { isLoggedIn } = useContext(LoginContext)
  const navigate = useNavigate()
  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const [loanData, setLoanData] = useState({})

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState('')

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setLoanData((prevData) => ({ ...prevData, [name]: value }))
  }

  // const checkEmptyData = (data) => {
  //   for (const key in data) {
  //     if (data[key] === '') {
  //       setError(
  //         langue === 'english' ? 'No empty fields !' : 'Nenhum campo vazio !'
  //       )
  //       return false
  //     }
  //   }
  //   return true
  // }

  const handleLinkClicK = () => {
    const text =
      langue === 'english'
        ? "By applying for a loan here, you agree to your personal data being shared with the bank's loan department so that your application can be processed. If the request is accepted, you will be contacted via the e-mail address you provided when you opened your bank account."
        : 'Ao solicitar um empréstimo aqui, você concorda que seus dados pessoais sejam compartilhados com o departamento de empréstimos do banco para que seu pedido seja processado. Se o pedido for aceito, você será contatado através do endereço de e-mail fornecido quando abriu sua conta bancária.'

    Swal.fire({
      title:
        langue === 'english' ? 'Important message!' : 'Mensagem importante!',
      text: text,
      icon: 'info', // icône de l'alerte (success, error, warning, info, question)
      confirmButtonColor: '#3085d6', // couleur du bouton de confirmation
      confirmButtonText: 'OK', // texte du bouton de confirmation
      showCancelButton: false, // affiche ou masque le bouton d'annulation
    })
  }

  // User Data
  useEffect(() => {
    if (token) {
      fetch(`${process.env.REACT_APP_BASE_URL}/auth/profile`, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`, // Inclure le token JWT dans l'en-tête Authorization
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data)
          setIsLoading(false) // Mettre isLoading à false une fois les données chargées
          setLoanData({
            object: '',
            amount: '',
            period: '',
            rate: '',
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
          })
        })
        .catch((error) => {
          // console.error('Error fetching user data:', error)
          setIsLoading(false) // isLoading à false même en cas d'erreur
        })
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      localStorage.removeItem('userRole')
      localStorage.removeItem('tokenExpiration')
      navigate('/login')
    }
  }, [token, navigate]) // token dans les dépendances pour recharger les données lorsque user token change

  // setLoanData(loanData)
  const checkEmptyData = () => {
    if (
      loanData.object === '' ||
      loanData.amount === '' ||
      loanData.period === ''
    ) {
      setError(
        langue === 'english' ? 'No empty fields !' : 'Nenhum campo vazio !'
      )
      return false
    }
    return true
  }

  // Send mail

  const handleSubmit = () => {
    checkEmptyData()

    fetch(`${process.env.REACT_APP_BASE_URL}/loan/loan-request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loanData),
    })
      .then((response) => {
        if (response.ok) {
          navigate('/loan')
          setError('')
          setLoanData({
            object: '',
            amount: '',
            period: '',
            rate: '',
            name: `${userData.firstName} ${userData.lastName}`,
            email: userData.email,
          })
          setSuccess(
            langue === 'english'
              ? 'Your request has been sent successfully!'
              : 'O seu pedido foi enviado com sucesso!'
          )
        } else {
          setError(
            langue === 'english'
              ? 'Something is wrong. Refresh the page and try again.'
              : 'Algo está errado. Actualize a página e tente novamente.'
          )
        }
      })
      .catch((error) => {
        console.error('Error sending email:', error)
      })
  }

  return (
    isLoggedIn && (
      <div
        className="loanFormWrapper"
        style={{ marginTop: '77px' }}
        langue={langue}
      >
        <h1>
          {langue === 'english'
            ? 'Loan amortization table'
            : 'Tabela de amortização do empréstimo'}
        </h1>
        <div className="loan_info">
          {langue === 'english' ? 'Important ! Click ' : 'Importante ! Clique '}
          <Link onClick={handleLinkClicK} className="alert">
            {langue === 'english' ? 'Here' : 'Aqui'}
          </Link>{' '}
          {langue === 'english' ? 'first.' : 'primeiro.'}
        </div>
        <p className="loan_info">
          {langue === 'english'
            ? 'Please complete this form carefully to apply for a loan!'
            : 'Por favor, preencha este formulário com cuidado para solicitar um empréstimo!'}
        </p>
        {isLoading ? (
          <div style={{ margin: '145px 0', width: '100%' }}>
            <Loader />
          </div>
        ) : (
          <>
            <h3
              style={{
                color: 'green',
                fontSize: '20px',
                textAlign: 'center',
                marginTop: '10px',
                marginBottom: '-10px',
              }}
            >
              {success}
            </h3>

            <form onSubmit={(e) => e.preventDefault()} className="fromForLoan">
              <div className="loan_form">
                {/* begining part 1 of register's informations. */}

                <div>
                  <label className="">
                    {langue === 'english'
                      ? 'Why do you need a loan ? Please provid us some explanation'
                      : 'Por que você precisa de um empréstimo? Por favor, forneça-nos alguma explicação'}
                  </label>
                  <textarea
                    name="object"
                    value={loanData.object}
                    onChange={handleInputChange}
                    id="object"
                    rows="5"
                    // cols="30"
                    autoCapitalize="sentences"
                    placeholder={
                      langue === 'english' ? 'Write here...' : 'Escreva aqui...'
                    }
                    required
                  ></textarea>
                </div>
                <div>
                  <label className="">
                    {langue === 'english'
                      ? 'Amount borrowed (euros)'
                      : 'Valor emprestado (euros)'}
                  </label>
                  <input
                    type="text"
                    name="amount"
                    value={loanData.amount}
                    onChange={handleInputChange}
                    id="amount"
                    className="register-input"
                    required
                  />
                </div>
                <div>
                  <label className="">
                    {langue === 'english'
                      ? 'Borrowing period: (in months)'
                      : 'Período de empréstimo: (em meses)'}
                  </label>
                  <input
                    type="text"
                    name="period"
                    value={loanData.period}
                    onChange={handleInputChange}
                    id="period"
                    className="register-input"
                    required
                    hidden
                  />
                </div>
                <div>
                  <label className="">
                    {langue === 'english'
                      ? 'Nominal annual rate(%) :'
                      : 'Taxa anual nominal (%):'}
                  </label>
                  <input
                    type="text"
                    name="rate"
                    className="register-input"
                    value={
                      langue === 'english'
                        ? '2.5 (this is fixed by the bank)'
                        : '2.5 (este valor é fixado pelo banco)'
                    }
                    required
                    disabled
                  />
                  <input
                    type="hidden"
                    name="name"
                    value={loanData.name}
                    disabled
                  />
                  <input
                    type="hidden"
                    name="email"
                    value={loanData.email}
                    disabled
                  />
                </div>
              </div>
              <h3 style={{ color: 'red' }}> {error} </h3>

              <div className="btn_container loan_btn">
                <input
                  type="reset"
                  value={langue === 'english' ? 'Cancel' : 'Cancelar'}
                  className="cancel"
                />

                <input
                  type="submit"
                  target="_top"
                  onClick={() => {
                    if (checkEmptyData(loanData)) {
                      handleSubmit()
                    }
                  }}
                  className="connect_me"
                  value={langue === 'english' ? 'Continue' : 'Continuar'}
                />

                {/* </button> */}
              </div>
            </form>
          </>
        )}
      </div>
    )
  )
}

export default Loan
