import React from 'react'
import { Link } from 'react-router-dom'
import TeamList from '../../components/Team'
import './team.css'
import { useLangue } from '../../utils/hooks'

function Team() {
  const { langue } = useLangue()
  return (
    <div className="teamWrapper" langue={langue}>
      <div className="team_top">
        <div className="team_top_color">
          <h1 className="team_top_h1">
            {langue === 'english'
              ? 'Team'
              : langue === 'portuguais'
              ? 'Equipe'
              : 'Team'}
          </h1>
          <div className="team_top_div">
            <Link to="/" className="team_link">
              {langue === 'english'
                ? 'Home /'
                : langue === 'portuguais'
                ? 'Início /'
                : 'Startseite /'}
            </Link>
            <p>
              {langue === 'english'
                ? 'Team'
                : langue === 'portuguais'
                ? 'Equipe'
                : 'Team'}
            </p>
          </div>
        </div>
      </div>
      <div>
        <TeamList />
      </div>
    </div>
  )
}

export default Team
