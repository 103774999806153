import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import { LoginContext } from '../../utils/context'
import { Loader } from '../../utils/style/loader'
import { useLangue } from '../../utils/hooks'
import Swal from 'sweetalert2'
import './accountOperation.css'

function AccountOperation() {
  const { langue } = useLangue()
  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  const handleLinkClicK = () => {
    const text =
      langue === 'english'
        ? "You are currently unable to perform this operation. Please try again or contact the bank's customer service department if the problem persists."
        : langue === 'portuguais'
        ? 'Atualmente, não é possível efetuar esta operação. Tente novamente ou contacte o serviço de apoio ao cliente do banco se o problema persistir.'
        : 'Derzeit können Sie diese Operation nicht durchführen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice der Bank, wenn das Problem weiterhin besteht.'

    Swal.fire({
      title:
        langue === 'english'
          ? 'Alert'
          : langue === 'portuguais'
          ? 'Alerta'
          : 'Warnung',
      text: text,
      icon: 'info', // icône de l'alerte (success, error, warning, info, question)
      confirmButtonColor: '#3085d6', // couleur du bouton de confirmation
      confirmButtonText: 'OK', // texte du bouton de confirmation
      showCancelButton: false, // affiche ou masque le bouton d'annulation
    })
  }

  // const { isLoggedIn } = useContext(LoginContext)

  useEffect(() => {
    if (token) {
      fetch(`${process.env.REACT_APP_BASE_URL}/auth/profile`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`, // Inclure le token JWT dans l'en-tête Authorization
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data)
          setIsLoading(false) // Mettre isLoading à false une fois les données chargées
        })
        .catch((error) => {
          console.error('Error fetching user data:', error)
          setIsLoading(false) // isLoading à false même en cas d'erreur
        })
    } else {
      localStorage.clear()
      navigate('/login')
    }
  }, [token, navigate]) // token dans les dépendances pour recharger les données lorsque user token change

  return isLoading ? (
    <div style={{ margin: '145px 0', width: '100%' }}>
      <Loader />
    </div>
  ) : (
    <div className="myaccount_container" langue={langue}>
      <h1 style={{ fontStyle: 'italic' }}>€ {userData.solde}</h1>
      <div className="actions">
        <div className="btn_op payment_btn">
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault()
              handleLinkClicK()
            }}
            className="link"
          >
            {langue === 'english'
              ? 'Payment'
              : langue === 'portuguais'
              ? 'Pagamento'
              : 'Zahlung'}
          </Link>
        </div>
        <div className="btn_op withdrawal_btn">
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault()
              handleLinkClicK()
            }}
            className="link"
          >
            {langue === 'english'
              ? 'Withdrawal'
              : langue === 'portuguais'
              ? 'Retirada'
              : 'Abhebung'}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AccountOperation
