import team_1 from '../assets/team/team-1.jpg'
import team_2 from '../assets/team/team-2.jpg'
import team_3 from '../assets/team/team-3.jpg'
import team_4 from '../assets/team/team-4.jpg'

export const teamList = [
  {
    name: 'Sven Schmid',
    title: 'Bank Director',
    image: team_1,
    title_de: 'Bankdirektor',
    name_pt: 'Sven Schmid',
    title_pt: 'Diretor de Banco',
  },
  {
    name: 'Simone Fuhrmann',
    title: 'Bank manager',
    image: team_2,
    title_de: 'Bankmanager',
    name_pt: 'Simone Fuhrmann',
    title_pt: 'Gerente de Banco',
  },
  {
    name: 'Matthias Zweig',
    title: 'Bank notary',
    image: team_3,
    title_de: 'Banknotar',
    name_pt: 'Matthias Zweig',
    title_pt: 'Notário Bancário',
  },
  {
    name: 'Martina Hofmann',
    title: 'Bank Secretary',
    image: team_4,
    title_de: 'Banksekretärin',
    name_pt: 'Martina Hofmann',
    title_pt: 'Secretária de Banco',
  },
]
