import { useState, useEffect, useRef } from 'react'

const useIdleTimer = (timeout, onIdle) => {
  const [isIdle, setIsIdle] = useState(false)
  const timeoutRef = useRef(null)
  const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart']

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    setIsIdle(false)
    timeoutRef.current = setTimeout(() => {
      setIsIdle(true)
      onIdle()
    }, timeout)
  }

  useEffect(() => {
    events.forEach((event) => window.addEventListener(event, resetTimer))

    resetTimer()

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer))
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [events])

  return isIdle
}

export default useIdleTimer
