import React from 'react'
import { Link } from 'react-router-dom'
import './bankAccount.css'
import { useLangue } from '../../utils/hooks'

function BankAccount() {
  const { langue } = useLangue()

  return (
    <div className="bankAccountWrapper" langue={langue}>
      <div className="insurance_top">
        <div className="insurance_top_color">
          <h1 className="insurance_top_h1">
            {langue === 'english'
              ? 'Bank account'
              : langue === 'portuguais'
              ? 'Conta Bancária'
              : 'Bankkonto'}
          </h1>
          <div className="insurance_top_div">
            <Link to="/" className="home_link">
              {langue === 'english'
                ? 'Home /'
                : langue === 'portuguais'
                ? 'Início /'
                : 'Startseite /'}
            </Link>
            <p>
              {langue === 'english'
                ? 'Bank account'
                : langue === 'portuguais'
                ? 'Conta Bancária'
                : 'Bankkonto'}
            </p>
          </div>
        </div>
      </div>

      <div className="bankAccount">
        <h1 className="bankAccount_h1">
          {langue === 'english'
            ? 'Bank Account - Asly Online Banking'
            : langue === 'portuguais'
            ? 'Conta Bancária - Banco Online Asly'
            : 'Bankkonto - Asly Online-Banking'}
        </h1>
        <div className="bankAccount_h1_bottom"></div>
        <div className="bankAccount_content">
          <div>
            <p>
              {langue === 'english'
                ? 'Welcome to the page dedicated to the bank accounts of our financial institution. Opening an account with us offers a variety of benefits tailored to your financial needs. Explore our different account options to find out how we can help you manage your finances.'
                : langue === 'portuguais'
                ? 'Bem-vindo à página dedicada às contas bancárias de nossa instituição financeira. Abrir uma conta conosco oferece uma variedade de benefícios adaptados às suas necessidades financeiras. Explore nossas diferentes opções de conta para descobrir como podemos ajudá-lo a gerenciar suas finanças.'
                : 'Willkommen auf der Seite, die den Bankkonten unserer Finanzinstitution gewidmet ist. Das Eröffnen eines Kontos bei uns bietet eine Vielzahl von Vorteilen, die auf Ihre finanziellen Bedürfnisse abgestimmt sind. Entdecken Sie unsere verschiedenen Kontomodelle, um herauszufinden, wie wir Ihnen helfen können, Ihre Finanzen zu verwalten.'}
            </p>
          </div>

          <div>
            <h3>
              {langue === 'english'
                ? 'Account Types'
                : langue === 'portuguais'
                ? 'Tipos de Conta'
                : 'Kontotypen'}
            </h3>
            <h2>
              1.{' '}
              {langue === 'english'
                ? 'Current account'
                : langue === 'portuguais'
                ? 'Conta Corrente'
                : 'Girokonto'}
            </h2>
            <p>
              {langue === 'english'
                ? 'The current account is designed to meet your daily financial management needs. With no account maintenance fees, it offers maximum flexibility for your daily transactions. Discover the advantages of this account and the opening conditions.'
                : langue === 'portuguais'
                ? 'A conta corrente foi projetada para atender às suas necessidades diárias de gerenciamento financeiro. Sem taxas de manutenção de conta, oferece máxima flexibilidade para suas transações diárias. Descubra as vantagens desta conta e as condições de abertura.'
                : 'Das Girokonto ist darauf ausgelegt, Ihre täglichen Finanzmanagementbedürfnisse zu erfüllen. Ohne Kontoführungsgebühren bietet es maximale Flexibilität für Ihre täglichen Transaktionen. Entdecken Sie die Vorteile dieses Kontos und die Eröffnungsbedingungen.'}
            </p>

            <h2>
              2.{' '}
              {langue === 'english'
                ? 'Savings account'
                : langue === 'portuguais'
                ? 'Conta Poupança'
                : 'Sparkonto'}
            </h2>
            <p>
              {langue === 'english'
                ? 'Our savings account allows you to grow your money with competitive interest rates. Learn more about the benefits, withdrawal methods and conditions for opening this account.'
                : langue === 'portuguais'
                ? 'Nossa conta poupança permite que você faça seu dinheiro crescer com taxas de juros competitivas. Saiba mais sobre os benefícios, métodos de saque e condições para abrir esta conta.'
                : 'Unser Sparkonto ermöglicht es Ihnen, Ihr Geld mit wettbewerbsfähigen Zinssätzen zu vermehren. Erfahren Sie mehr über die Vorteile, Abhebemethoden und Bedingungen für die Eröffnung dieses Kontos.'}
            </p>

            <h2>
              3.{' '}
              {langue === 'english'
                ? 'Young Checking Account'
                : langue === 'portuguais'
                ? 'Conta Corrente Jovem'
                : 'Junges Girokonto'}
            </h2>
            <p>
              {langue === 'english'
                ? 'Intended for young professionals, the Youth Checking Account offers specific advantages to support your first steps in the financial world. Learn about the unique features and eligibility requirements.'
                : langue === 'portuguais'
                ? 'Destinada a jovens profissionais, a Conta Corrente Jovem oferece vantagens específicas para apoiar seus primeiros passos no mundo financeiro. Saiba mais sobre os recursos exclusivos e os requisitos de elegibilidade.'
                : 'Das Junge Girokonto richtet sich an junge Berufseinsteiger und bietet spezifische Vorteile, um Ihre ersten Schritte in der Finanzwelt zu unterstützen. Erfahren Sie mehr über die einzigartigen Funktionen und die Anforderungen für die Berechtigung.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Opening Procedure'
                : langue === 'portuguais'
                ? 'Procedimento de Abertura'
                : 'Eröffnungsverfahren'}
            </h2>
            <p>
              {langue === 'english'
                ? 'Opening an account with us is quick and easy. Follow our step-by-step guide to find out what documents are needed, online or in-branch options, and how long it takes to activate your account.'
                : langue === 'portuguais'
                ? 'Abrir uma conta conosco é rápido e fácil. Siga nosso guia passo a passo para descobrir quais documentos são necessários, opções online ou na agência e quanto tempo leva para ativar sua conta.'
                : 'Das Eröffnen eines Kontos bei uns ist schnell und einfach. Folgen Sie unserem Schritt-für-Schritt-Leitfaden, um herauszufinden, welche Dokumente benötigt werden, welche Online- oder Filialoptionen verfügbar sind und wie lange es dauert, Ihr Konto zu aktivieren.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'General Benefits'
                : langue === 'portuguais'
                ? 'Benefícios Gerais'
                : 'Allgemeine Vorteile'}
            </h2>
            <p>
              {langue === 'english'
                ? 'By choosing our institution, you benefit from many advantages, such as the security of your funds, convenient access to our online services, dedicated customer service and much more. Find out what makes our bank the ideal partner for your financial needs.'
                : langue === 'portuguais'
                ? 'Ao escolher nossa instituição, você se beneficia de muitas vantagens, como a segurança de seus fundos, acesso conveniente aos nossos serviços online, atendimento ao cliente dedicado e muito mais. Descubra o que torna nosso banco o parceiro ideal para suas necessidades financeiras.'
                : 'Wenn Sie sich für unsere Institution entscheiden, profitieren Sie von vielen Vorteilen, wie der Sicherheit Ihrer Gelder, dem bequemen Zugang zu unseren Online-Diensten, dem engagierten Kundenservice und vielem mehr. Erfahren Sie, was unsere Bank zum idealen Partner für Ihre finanziellen Bedürfnisse macht.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Frequently Asked Questions (FAQ)'
                : langue === 'portuguais'
                ? 'Perguntas Frequentes (PF)'
                : 'Häufig Gestellte Fragen (HGF)'}
            </h2>
            <p>
              {langue === 'english'
                ? 'To answer your frequently asked questions, consult our FAQ section dedicated to bank accounts. Find quick answers to the most common questions and get the information you need.'
                : langue === 'portuguais'
                ? 'Para responder às suas perguntas frequentes, consulte nossa seção de FAQ dedicada a contas bancárias. Encontre respostas rápidas para as perguntas mais comuns e obtenha as informações de que precisa.'
                : 'Um Ihre häufig gestellten Fragen zu beantworten, konsultieren Sie bitte unseren FAQ-Bereich, der den Bankkonten gewidmet ist. Finden Sie schnelle Antworten auf die häufigsten Fragen und erhalten Sie die Informationen, die Sie benötigen.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Contact'
                : langue === 'portuguais'
                ? 'Contato'
                : 'Kontakt'}
            </h2>
            <p>
              {langue === 'english'
                ? 'If you have additional questions or require personalized assistance, please do not hesitate to contact us. Our team is here to help you choose the account that best suits your needs.'
                : langue === 'portuguais'
                ? 'Se você tiver perguntas adicionais ou precisar de assistência personalizada, não hesite em nos contatar. Nossa equipe está aqui para ajudá-lo a escolher a conta que melhor atenda às suas necessidades.'
                : 'Wenn Sie zusätzliche Fragen haben oder persönliche Unterstützung benötigen, zögern Sie bitte nicht, uns zu kontaktieren. Unser Team hilft Ihnen gerne, das Konto auszuwählen, das am besten zu Ihren Bedürfnissen passt.'}
            </p>
            <p>
              {langue === 'english'
                ? 'Open the door to simple and efficient financial management by choosing one of our bank accounts. We are delighted to support you on your financial journey.'
                : langue === 'portuguais'
                ? 'Abra as portas para uma gestão financeira simples e eficiente escolhendo uma de nossas contas bancárias. Estamos felizes em apoiá-lo em sua jornada financeira.'
                : 'Öffnen Sie die Tür zu einer einfachen und effizienten Finanzverwaltung, indem Sie eines unserer Bankkonten wählen. Wir freuen uns, Sie auf Ihrer finanziellen Reise zu unterstützen.'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankAccount
