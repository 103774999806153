import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Loader } from '../../utils/style/loader'
// import { useFetch } from '../../utils/hooks'
import './profile.css'
import { useLangue } from '../../utils/hooks'

import IdPicture from '../../assets/profile/nopicture.jpg'

function Profile() {
  const { langue } = useLangue()
  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      fetch(`${process.env.REACT_APP_BASE_URL}/auth/profile`, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`, // Inclure le token JWT dans l'en-tête Authorization
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data)
          setIsLoading(false) // Mettre isLoading à false une fois les données chargées
        })
        .catch((error) => {
          console.error('Error fetching user data:', error)
          setIsLoading(false) // isLoading à false même en cas d'erreur
        })
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      localStorage.removeItem('userRole')
      localStorage.removeItem('tokenExpiration')
      navigate('/login')
    }
  }, [token, navigate]) // token dans les dépendances pour recharger les données lorsque user token change

  return (
    <div className="ProfileWrapper" langue={langue}>
      <div className="contact_top">
        <div className="contact_top_color">
          <h1 className="contact_top_h1">
            {langue === 'english'
              ? 'Profile'
              : langue === 'portuguais'
              ? 'Perfil'
              : 'Profil'}
          </h1>
          <div className="contact_top_div">
            <Link to="/user_dashbord" className="home_link">
              {langue === 'english'
                ? 'Home /'
                : langue === 'portuguais'
                ? 'Início /'
                : 'Startseite /'}
            </Link>
            <p>
              {langue === 'english'
                ? 'Profile'
                : langue === 'portuguais'
                ? 'Perfil'
                : 'Profil'}
            </p>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div style={{ margin: '145px 0', width: '100%' }}>
          <Loader />
        </div>
      ) : (
        <div>
          <div className="profil_content border profil_info ">
            <div className="profil_content_1">
              <div className="border">
                <h2>
                  {langue === 'english'
                    ? 'Profile picture'
                    : langue === 'portuguais'
                    ? 'Foto de perfil'
                    : 'Profilbild'}
                </h2>
                <div className="picture">
                  <h1>
                    {userData.firstName[0]}
                    {userData.lastName[0]}
                  </h1>
                </div>
              </div>
              <div className="border">
                <h2>
                  {langue === 'english'
                    ? 'ID card'
                    : langue === 'portuguais'
                    ? 'Documento de identidade'
                    : 'Personalausweis'}
                </h2>
                <div className="cardID idpicture">
                  <img
                    src={IdPicture}
                    alt={
                      langue === 'english'
                        ? 'ID PICTURE HERE'
                        : langue === 'portuguais'
                        ? 'FOTO DO DOCUMENTO DE IDENTIFICAÇÃO AQUI'
                        : 'ID-BILD HIER'
                    }
                    className="id_picture"
                  />
                </div>
              </div>
            </div>
            <div className="profil_content_2 border">
              <div className="profil_title">
                <h2>
                  {langue === 'english'
                    ? 'Personal informations'
                    : langue === 'portuguais'
                    ? 'Informações pessoais'
                    : 'Persönliche Informationen'}
                </h2>
                <Link to="/update_user_profile" style={{ color: '#1d4ed8' }}>
                  {langue === 'english'
                    ? 'Edit'
                    : langue === 'portuguais'
                    ? 'Editar'
                    : 'Bearbeiten'}
                </Link>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'Civility :'
                    : langue === 'portuguais'
                    ? 'Civilidade :'
                    : 'Anrede :'}
                </p>
                <p className="label_value"> {userData.civility}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'Full Name :'
                    : langue === 'portuguais'
                    ? 'Nome completo :'
                    : 'Vollständiger Name :'}
                </p>
                <p className="label_value">
                  {userData.firstName} {userData.lastName}
                </p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'Date of birth :'
                    : langue === 'portuguais'
                    ? 'Data de nascimento :'
                    : 'Geburtsdatum :'}
                </p>
                <p className="label_value">{userData.birthday}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'E-mail address :'
                    : langue === 'portuguais'
                    ? 'Endereço de e-mail :'
                    : 'E-Mail-Adresse :'}
                </p>
                <p className="label_value">{userData.email}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'Phone number :'
                    : langue === 'portuguais'
                    ? 'Número de telefone :'
                    : 'Telefonnummer :'}
                </p>
                <p className="label_value">{userData.phone}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'Marital status :'
                    : langue === 'portuguais'
                    ? 'Estado civil :'
                    : 'Familienstand :'}
                </p>
                <p className="label_value">{userData.marital}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'Occupation :'
                    : langue === 'portuguais'
                    ? 'Ocupação :'
                    : 'Beruf :'}
                </p>
                <p className="label_value">{userData.occupation}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'Country :'
                    : langue === 'portuguais'
                    ? 'País :'
                    : 'Land :'}
                </p>
                <p className="label_value">{userData.country}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'City :'
                    : langue === 'portuguais'
                    ? 'Cidade :'
                    : 'Stadt :'}
                </p>
                <p className="label_value">{userData.city}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'Address :'
                    : langue === 'portuguais'
                    ? 'Endereço :'
                    : 'Adresse :'}
                </p>
                <p className="label_value">{userData.address}</p>
              </div>
            </div>
          </div>
          <div className="profil_content_3 border">
            <div className="profil_title">
              <h2>
                {langue === 'english'
                  ? 'Security'
                  : langue === 'portuguais'
                  ? 'Segurança'
                  : 'Sicherheit'}
              </h2>
              <Link to="/profile" style={{ color: '#1d4ed8' }}>
                {langue === 'english'
                  ? 'Edit'
                  : langue === 'portuguais'
                  ? 'Editar'
                  : 'Bearbeiten'}
              </Link>
            </div>
            <div className="row">
              <p className="label">
                {langue === 'english'
                  ? 'Password :'
                  : langue === 'portuguais'
                  ? 'Senha :'
                  : 'Passwort :'}
              </p>
              <p>***********</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Profile
