import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLangue } from '../../utils/hooks'

import Testimonials from '../../components/Testimonials'
import Services from '../../components/Services'
import WhyChooseUs from '../../components/WhyChooseUs'
import NeedLoan from '../../components/NeedLoan'
import LoanForm from '../../pages/LoanForm'
// import Iphone from '../../assets/home/iphone.png'
import IphoneEn from '../../assets/home/EN.png'
import IphonePt from '../../assets/home/PT.png'
import './home.css'

function Home() {
  const { langue } = useLangue()

  const testimonialsRef = useRef(null)
  const loanRef = useRef(null)

  useEffect(() => {
    if (window.location.hash === '#testimonials' && testimonialsRef.current) {
      testimonialsRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  useEffect(() => {
    if (window.location.hash === '#loanForm' && loanRef.current) {
      loanRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const scrollToLoan = () => {
    loanRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <main langue={langue}>
      <div className="home_part_1">
        <div className="home_part_1_color">
          <h1 className="home_part_1_h1">
            {langue === 'english'
              ? 'Fast, Reliable and Secure'
              : langue === 'portuguais'
              ? 'Rápido, Confiável e Seguro'
              : 'Schnell, Zuverlässig und Sicher'}
          </h1>
          <p className="home_part_1_p">
            {langue === 'english'
              ? 'We provide you with remote credit solutions, but also credit insurance in the event of death, illness, loss of job or accident.'
              : langue === 'portuguais'
              ? 'Fornecemos soluções de crédito remoto, além de seguro de crédito em caso de morte, doença, perda de emprego ou acidente.'
              : 'Wir bieten Ihnen Remote-Kreditlösungen sowie eine Kreditversicherung im Falle von Tod, Krankheit, Arbeitsplatzverlust oder Unfall an.'}
          </p>
          <div className="home_part_1_btn_container">
            <Link to="" onClick={scrollToLoan} className="home_part_1_btn">
              {langue === 'english'
                ? 'Start now'
                : langue === 'portuguais'
                ? 'Comece agora'
                : 'Jetzt starten'}
            </Link>
          </div>
        </div>
      </div>
      <div>
        <WhyChooseUs />
      </div>

      <div>
        <Services />
      </div>

      <div>
        <NeedLoan scrollToLoan={scrollToLoan} />
      </div>

      <div className="home_part_4">
        <div className="home_part_4_left">
          <div className="home_part_4_left_1">
            <h2 className="home_part_4_left_1_h2">
              {langue === 'english'
                ? 'Our different features'
                : langue === 'portuguais'
                ? 'Nossos recursos diferentes'
                : 'Unsere verschiedenen Funktionen'}
            </h2>
            <div className="home_part_4_left_1_div">
              <div className="item">
                {langue === 'english'
                  ? 'Quick Loans Online'
                  : langue === 'portuguais'
                  ? 'Empréstimos Rápidos Online'
                  : 'Schnelle Online-Kredite'}
              </div>
              <div className="item">
                {langue === 'english'
                  ? 'Instant Transfers'
                  : langue === 'portuguais'
                  ? 'Transferências Instantâneas'
                  : 'Sofortüberweisungen'}
              </div>
              <div className="item">
                {langue === 'english'
                  ? 'Automatic Savings'
                  : langue === 'portuguais'
                  ? 'Economia Automática'
                  : 'Automatisches Sparen'}
              </div>
              <div className="item">
                {langue === 'english'
                  ? 'Personalized Alerts'
                  : langue === 'portuguais'
                  ? 'Alertas Personalizados'
                  : 'Personalisierte Alarme'}
              </div>
              <div className="item">
                {langue === 'english'
                  ? 'Smart Investments'
                  : langue === 'portuguais'
                  ? 'Investimentos Inteligentes'
                  : 'Intelligente Investitionen'}
              </div>
              <div className="item">
                {langue === 'english'
                  ? 'Secure Virtual Cards'
                  : langue === 'portuguais'
                  ? 'Cartões Virtuais Seguros'
                  : 'Sichere Virtuelle Karten'}
              </div>
              <div className="item">
                {langue === 'english'
                  ? 'Rewards Program'
                  : langue === 'portuguais'
                  ? 'Programa de Recompensas'
                  : 'Belohnungsprogramm'}
              </div>
              <div className="item">
                {langue === 'english'
                  ? 'Full Mobile Access'
                  : langue === 'portuguais'
                  ? 'Acesso Total pelo Celular'
                  : 'Voller Mobiler Zugang'}
              </div>
            </div>
          </div>
          <div className="home_part_4_left_2">
            <h2 className="home_part_4_left_2_h2">
              {langue === 'english'
                ? 'Are you sure of your abilities?'
                : langue === 'portuguais'
                ? 'Tem certeza de suas habilidades?'
                : 'Sind Sie sich Ihrer Fähigkeiten sicher?'}
            </h2>
            <p className="home_part_4_left_2_p">
              {langue === 'english'
                ? 'A loan binds you and must be repaid. Check your repayment capacity before you commit.'
                : langue === 'portuguais'
                ? 'Um empréstimo o vincula e deve ser pago. Verifique sua capacidade de pagamento antes de se comprometer.'
                : 'Ein Kredit bindet Sie und muss zurückgezahlt werden. Überprüfen Sie Ihre Rückzahlungsfähigkeit, bevor Sie sich verpflichten.'}
            </p>
            <div className="home_part_4_btn_container">
              <Link to="" onClick={scrollToLoan} className="home_part_4_btn">
                {langue === 'english'
                  ? 'Start now'
                  : langue === 'portuguais'
                  ? 'Comece agora'
                  : 'Jetzt starten'}
              </Link>
            </div>
          </div>
        </div>
        <img
          // src={Iphone}
          src={
            langue === 'english'
              ? IphoneEn
              : langue === 'portuguais'
              ? IphonePt
              : IphoneEn
          }
          alt="iphone"
          height="800em"
          className="home_part_4_img"
        />
      </div>

      <div id="testimonials" ref={testimonialsRef}>
        <Testimonials />
      </div>

      <div id="loanForm" ref={loanRef}>
        <LoanForm />
      </div>
    </main>
  )
}

export default Home
