import React from 'react'
import { servicesList } from '../../data/servicesItems'
import './servicesItems.css'
import { useLangue } from '../../utils/hooks'

function ServicePart1() {
  const { langue } = useLangue()
  return (
    <div className="serviceItemsWrapper" langue={langue}>
      {servicesList.map((it, index) => (
        <div className="service_item" key={`${it.title}-${index}`}>
          <img src={it.picture} alt="img" />
          <div>
            <h3>
              {langue === 'english'
                ? it.title
                : langue === 'portuguais'
                ? it.title_pt
                : it.title_de}
            </h3>
            <p>
              {langue === 'english'
                ? it.description
                : langue === 'portuguais'
                ? it.description_pt
                : it.description_de}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ServicePart1
