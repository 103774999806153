import cards_1 from '../assets/services/cards-1.jpg'
import cards_2 from '../assets/services/cards-2.jpg'
import cards_3 from '../assets/services/cards-3.jpg'
import cards_4 from '../assets/services/cards-4.jpg'
import cards_5 from '../assets/services/cards-5.jpg'
import cards_6 from '../assets/services/cards-6.jpg'
import cards_7 from '../assets/services/cards-7.jpg'
import cards_8 from '../assets/services/cards-8.jpg'

export const servicesList = [
  {
    title: 'Personal loan',
    description:
      'The personal loan facilitates the financing of projects without initial contribution. The repayment period, adapted to your financial capacity, can extend over several months to several years.',
    picture: cards_1,
    title_pt: 'Empréstimo Pessoal',
    description_pt:
      'O empréstimo pessoal facilita o financiamento de projetos sem contribuição inicial. O período de pagamento, adaptado à sua capacidade financeira, pode se estender de alguns meses a vários anos.',
    description_de:
      'Das persönliche Darlehen ermöglicht die Finanzierung von Projekten ohne Eigenleistung. Die Rückzahlungsfrist, die an Ihre finanzielle Leistungsfähigkeit angepasst ist, kann sich über mehrere Monate bis zu mehreren Jahren erstrecken.',
  },
  {
    title: 'Real Estate Loan',
    description:
      'The real estate credit, or real estate loan, is a loan intended to finance all or part of the acquisition of real estate, the construction operation; or work on such property. Real estate credit is regulated by law.',
    picture: cards_2,
    title_pt: 'Empréstimo Imobiliário',
    description_pt:
      'O crédito imobiliário, ou empréstimo imobiliário, é um empréstimo destinado a financiar total ou parcialmente a aquisição de bens imóveis, a operação de construção ou obras em tais propriedades. O crédito imobiliário é regulamentado por lei.',
    description_de:
      'Der Immobilienkredit oder das Immobiliendarlehen ist ein Darlehen, das zur vollständigen oder teilweisen Finanzierung des Erwerbs einer Immobilie, der Baumaßnahmen oder der Arbeiten an einer solchen Immobilie bestimmt ist. Der Immobilienkredit ist gesetzlich geregelt.',
  },
  {
    title: 'Car Loan',
    description:
      'Auto credit, a personal loan dedicated to the purchase of your car, offers rates varying depending on the amount, duration of the credit, and type of vehicle. (rates may be different for a new car compared to a used car).',
    picture: cards_3,
    title_pt: 'Empréstimo para Carro',
    description_pt:
      'O crédito automóvel, um empréstimo pessoal dedicado à compra do seu carro, oferece taxas que variam dependendo do valor, da duração do crédito e do tipo de veículo. (as taxas podem ser diferentes para um carro novo em comparação com um usado).',
    description_de:
      'Der Autokredit, ein persönliches Darlehen für den Kauf Ihres Autos, bietet unterschiedliche Zinssätze, die von der Höhe des Betrags, der Laufzeit des Kredits und der Art des Fahrzeugs abhängen. (Die Zinssätze können für ein neues Auto anders sein als für einen Gebrauchtwagen).',
  },
  {
    title: 'Investment Loan',
    description:
      'An investment loan is a form of financing dedicated to business projects such as the purchase of equipment or construction. Conditions adapted to long-term repayment, promoting growth and competitiveness.',
    picture: cards_4,
    title_pt: 'Empréstimo de Investimento',
    description_pt:
      'Um empréstimo de investimento é uma forma de financiamento dedicada a projetos empresariais, como a compra de equipamentos ou a construção. Condições adaptadas ao pagamento a longo prazo, promovendo o crescimento e a competitividade.',
    description_de:
      'Ein Investitionskredit ist eine Finanzierungsform für Unternehmensprojekte wie den Kauf von Ausrüstungen oder Bauvorhaben. Die Konditionen sind auf eine langfristige Rückzahlung ausgerichtet und fördern Wachstum und Wettbewerbsfähigkeit.',
  },
  {
    title: 'Debt Consolidation Loan',
    description:
      'A debt consolidation loan consolidates several debts into one, simplifying payments with a potential lower interest rate.',
    picture: cards_5,
    title_pt: 'Empréstimo de Consolidação de Dívidas',
    description_pt:
      'Um empréstimo de consolidação de dívidas consolida várias dívidas em uma só, simplificando os pagamentos com uma taxa de juros potencialmente mais baixa.',
    description_de:
      'Ein Schuldenkonsolidierungsdarlehen fasst mehrere Schulden zu einer einzigen zusammen und vereinfacht so die Zahlungen mit einem möglicherweise niedrigeren Zinssatz.',
  },
  {
    title: 'Mortgage',
    description:
      'A mortgage loan is a form of borrowing secured by a property, allowing the purchase of real estate with repayment over a defined period.',
    picture: cards_6,
    title_pt: 'Hipoteca',
    description_pt:
      'Um empréstimo hipotecário é uma forma de empréstimo garantido por uma propriedade, permitindo a compra de bens imóveis com pagamento ao longo de um período definido.',
    description_de:
      'Ein Hypothekendarlehen ist eine durch eine Immobilie gesicherte Kreditaufnahme, die den Erwerb einer Immobilie mit Rückzahlung über einen bestimmten Zeitraum ermöglicht.',
  },
  {
    title: 'Loan Repurchase',
    description:
      'A buyout loan consolidates various loans into one, simplifying payments with potentially more favorable terms.',
    picture: cards_7,
    title_pt: 'Empréstimo de Recompra',
    description_pt:
      'Um empréstimo de recompra consolida vários empréstimos em um só, simplificando os pagamentos com termos potencialmente mais favoráveis.',
    description_de:
      'Ein Buyout-Darlehen fasst verschiedene Kredite zu einem einzigen zusammen und vereinfacht so die Zahlungen mit potenziell günstigeren Konditionen.',
  },
  {
    title: 'Asly Bank',
    description:
      'Insurance for your Asly Bank home loan Fixed amount of contributions excluding tax for the entire duration of your loan. Loyalty rewarded: 20% reduction for any second membership to Asly Bank.',
    picture: cards_8,
    title_pt: 'Asly Bank',
    description_pt:
      'Seguro para o seu empréstimo hipotecário do Asly Bank Valor fixo de contribuições excluindo impostos durante toda a duração do seu empréstimo. Fidelidade premiada: 20% de redução para qualquer segunda adesão ao Asly Bank.',
    description_de:
      'Versicherung für Ihr Asly Bank-Wohnungsbaudarlehen Feste Beitragssumme ohne Steuern für die gesamte Laufzeit Ihres Darlehens. Belohnte Treue: 20% Ermäßigung für jede zweite Mitgliedschaft bei der Asly Bank.',
  },
]
