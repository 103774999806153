import React from 'react'
import { Link } from 'react-router-dom'
import './confidentiality.css'
import { useLangue } from '../../utils/hooks'

function Confidentiality() {
  const { langue } = useLangue()

  return (
    <div className="confidentialityWrapper" langue={langue}>
      <div className="insurance_top">
        <div className="insurance_top_color">
          <h1 className="insurance_top_h1">
            {langue === 'english'
              ? 'Privacy Policy'
              : langue === 'portuguais'
              ? 'Política de Privacidade'
              : 'Datenschutzerklärung'}
          </h1>
          <div className="insurance_top_div">
            <Link to="/" className="home_link">
              {langue === 'english'
                ? 'Home /'
                : langue === 'portuguais'
                ? 'Início /'
                : 'Startseite /'}
            </Link>
            <p>
              {langue === 'english'
                ? 'Privacy Policy'
                : langue === 'portuguais'
                ? 'Política de Privacidade'
                : 'Datenschutzerklärung'}
            </p>
          </div>
        </div>
      </div>
      <div className="confidentiality">
        <h1 className="confidentiality_h1">
          {langue === 'english'
            ? 'Asly Online Bank Privacy Policy'
            : langue === 'portuguais'
            ? 'Política de Privacidade do Banco Online Asly'
            : 'Datenschutzerklärung der Asly Online Bank'}
        </h1>
        <div className="confidentiality_h1_bottom"></div>

        <div className="confidentiality_content">
          <div>
            <p>
              {langue === 'english'
                ? 'Effective Date:'
                : langue === 'portuguais'
                ? 'Data Efetiva:'
                : 'Gültigkeitsdatum:'}{' '}
              <strong style={{ fontWeight: '500' }}>
                {langue === 'english'
                  ? 'September 18th, 1999'
                  : langue === 'portuguais'
                  ? '18 de Setembro de 1999'
                  : '18. September 1999'}
              </strong>
            </p>
            <p>
              {langue === 'english'
                ? "Asly Online Bank is committed to protecting the privacy of its customers. This privacy policy explains how we collect, use, disclose and protect our customers' personal information."
                : langue === 'portuguais'
                ? 'O Banco Online Asly está comprometido em proteger a privacidade de seus clientes. Esta política de privacidade explica como coletamos, usamos, divulgamos e protegemos as informações pessoais de nossos clientes.'
                : 'Die Asly Online Bank verpflichtet sich, die Privatsphäre ihrer Kunden zu schützen. Diese Datenschutzerklärung erklärt, wie wir die persönlichen Informationen unserer Kunden sammeln, verwenden, offenlegen und schützen.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'I. Collection of personal information'
                : langue === 'portuguais'
                ? 'I. Coleta de informações pessoais'
                : 'I. Erhebung persönlicher Daten'}
            </h2>
            <p>
              {langue === 'english'
                ? 'We collect the following personal information to provide online banking services and improve your experience:'
                : langue === 'portuguais'
                ? 'Coletamos as seguintes informações pessoais para fornecer serviços bancários online e melhorar sua experiência:'
                : 'Wir erheben die folgenden persönlichen Informationen, um Online-Banking-Dienste bereitzustellen und Ihre Erfahrung zu verbessern:'}{' '}
            </p>
            <ul>
              <li>
                {langue === 'english'
                  ? 'Identifying information (last name, first name, address, telephone number, etc.)'
                  : langue === 'portuguais'
                  ? 'Informações de identificação (sobrenome, nome, endereço, número de telefone, etc.)'
                  : 'Identifikationsdaten (Nachname, Vorname, Adresse, Telefonnummer usw.)'}
              </li>
              <li>
                {langue === 'english'
                  ? 'Financial information (accounts, transactions, history, etc.)'
                  : langue === 'portuguais'
                  ? 'Informações financeiras (contas, transações, histórico, etc.)'
                  : 'Finanzinformationen (Konten, Transaktionen, Verlauf usw.)'}
              </li>
              <li>
                {langue === 'english'
                  ? 'Connection information (email, password, etc.)'
                  : langue === 'portuguais'
                  ? 'Informações de conexão (e-mail, senha, etc.)'
                  : 'Verbindungsdaten (E-Mail, Passwort usw.)'}
              </li>
            </ul>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'II. Use of information'
                : langue === 'portuguais'
                ? 'II. Uso das informações'
                : 'II. Verwendung von Informationen'}
            </h2>
            <p>
              {langue === 'english'
                ? 'We use personal information to:'
                : langue === 'portuguais'
                ? 'Usamos informações pessoais para:'
                : 'Wir verwenden persönliche Informationen für:'}{' '}
            </p>
            <ul>
              <li>
                {langue === 'english'
                  ? 'Providing online banking services'
                  : langue === 'portuguais'
                  ? 'Fornecer serviços bancários online'
                  : 'Bereitstellung von Online-Banking-Diensten'}
              </li>
              <li>
                {langue === 'english'
                  ? 'Manage customer accounts and process transactions'
                  : langue === 'portuguais'
                  ? 'Gerenciar contas de clientes e processar transações'
                  : 'Verwaltung von Kundenkonten und Bearbeitung von Transaktionen'}
              </li>
              <li>
                {langue === 'english'
                  ? 'Personalize the user experience'
                  : langue === 'portuguais'
                  ? 'Personalizar a experiência do usuário'
                  : 'Personalisierung der Benutzererfahrung'}
              </li>
              <li>
                {langue === 'english'
                  ? 'Respond to customer service requests'
                  : langue === 'portuguais'
                  ? 'Responder a solicitações de serviço ao cliente'
                  : 'Antwort auf Kundenanfragen'}
              </li>
              <li>
                {langue === 'english'
                  ? 'Improve our products and services'
                  : langue === 'portuguais'
                  ? 'Melhorar nossos produtos e serviços'
                  : 'Verbesserung unserer Produkte und Dienstleistungen'}
              </li>
            </ul>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'III. Disclosure of information'
                : langue === 'portuguais'
                ? 'III. Divulgação de informações'
                : 'III. Offenlegung von Informationen'}
            </h2>
            <p>
              {langue === 'english'
                ? 'We only share your personal information with third parties in the following cases:'
                : langue === 'portuguais'
                ? 'Compartilhamos suas informações pessoais com terceiros apenas nos seguintes casos:'
                : 'Wir teilen Ihre persönlichen Daten nur in den folgenden Fällen mit Dritten:'}{' '}
            </p>
            <ul>
              <li>
                {langue === 'english'
                  ? 'When necessary to provide the services requested by the customer'
                  : langue === 'portuguais'
                  ? 'Quando necessário para fornecer os serviços solicitados pelo cliente'
                  : 'Wenn erforderlich, um die vom Kunden angeforderten Dienstleistungen bereitzustellen'}
              </li>
              <li>
                {langue === 'english'
                  ? 'To comply with applicable laws and regulations'
                  : langue === 'portuguais'
                  ? 'Para cumprir as leis e regulamentos aplicáveis'
                  : 'Zur Einhaltung geltender Gesetze und Vorschriften'}
              </li>
              <li>
                {langue === 'english'
                  ? 'With the explicit consent of the customer'
                  : langue === 'portuguais'
                  ? 'Com o consentimento explícito do cliente'
                  : 'Mit ausdrücklicher Zustimmung des Kunden'}
              </li>
            </ul>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'IV. Information security'
                : langue === 'portuguais'
                ? 'IV. Segurança da informação'
                : 'IV. Informationssicherheit'}
            </h2>
            <p>
              {langue === 'english'
                ? 'We take appropriate security measures to protect personal information from unauthorized access, alteration, disclosure or destruction.'
                : langue === 'portuguais'
                ? 'Tomamos medidas de segurança adequadas para proteger as informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição.'
                : 'Wir ergreifen angemessene Sicherheitsmaßnahmen, um persönliche Informationen vor unbefugtem Zugriff, Änderung, Offenlegung oder Zerstörung zu schützen.'}{' '}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'V. Cookies and similar technologies'
                : langue === 'portuguais'
                ? 'V. Cookies e tecnologias semelhantes'
                : 'V. Cookies und ähnliche Technologien'}
            </h2>
            <p>
              {langue === 'english'
                ? 'We use cookies and other similar technologies to improve navigation and personalize the online experience. Customers can manage their cookie preferences.'
                : langue === 'portuguais'
                ? 'Usamos cookies e outras tecnologias semelhantes para melhorar a navegação e personalizar a experiência online. Os clientes podem gerenciar suas preferências de cookies.'
                : 'Wir verwenden Cookies und andere ähnliche Technologien, um die Navigation zu verbessern und die Online-Erfahrung zu personalisieren. Kunden können ihre Cookie-Einstellungen verwalten.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'VI. Privacy Policy Updates'
                : langue === 'portuguais'
                ? 'VI. Atualizações da Política de Privacidade'
                : 'VI. Aktualisierungen der Datenschutzrichtlinie'}
            </h2>
            <p>
              {langue === 'english'
                ? 'This privacy policy may be updated periodically. Customers will be informed of any significant changes.'
                : langue === 'portuguais'
                ? 'Esta política de privacidade pode ser atualizada periodicamente. Os clientes serão informados de quaisquer alterações significativas.'
                : 'Diese Datenschutzerklärung kann regelmäßig aktualisiert werden. Kunden werden über wesentliche Änderungen informiert.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'VII. Contact'
                : langue === 'portuguais'
                ? 'VII. Contato'
                : 'VII. Kontakt'}
            </h2>
            <p>
              {langue === 'english'
                ? 'For questions regarding the privacy policy, please contact us at: contact@aslyonline.com.'
                : langue === 'portuguais'
                ? 'Para dúvidas sobre a política de privacidade, entre em contato conosco em: contact@aslyonline.com.'
                : 'Bei Fragen zur Datenschutzerklärung kontaktieren Sie uns bitte unter: contact@aslyonline.com.'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Confidentiality
