import React from 'react'
import { Link } from 'react-router-dom'
import './legalNotice.css'
import { useLangue } from '../../utils/hooks'

function LegalNotice() {
  const { langue } = useLangue()
  return (
    <div className="legalNoticeWrapper" langue={langue}>
      <div className="insurance_top">
        <div className="insurance_top_color">
          <h1 className="insurance_top_h1">
            {langue === 'english'
              ? 'Legal notice'
              : langue === 'portuguais'
              ? 'Aviso Legal'
              : 'Rechtliche Hinweise'}
          </h1>
          <div className="insurance_top_div">
            <Link to="/" className="home_link">
              {langue === 'english'
                ? 'Home /'
                : langue === 'portuguais'
                ? 'Início /'
                : 'Startseite /'}
            </Link>
            <p>
              {langue === 'english'
                ? 'Legal notice'
                : langue === 'portuguais'
                ? 'Aviso Legal'
                : 'Rechtliche Hinweise'}
            </p>
          </div>
        </div>
      </div>
      <div className="legalNotice">
        <h1 className="legalNotice_h1">
          {langue === 'english'
            ? 'Legal Notice - Asly Online Banking'
            : langue === 'portuguais'
            ? 'Aviso Legal - Asly Banco Online'
            : 'Rechtlicher Hinweis - Asly Online-Banking'}
        </h1>
        <div className="legalNotice_h1_bottom"></div>

        <div className="legalNotice_content">
          <div>
            <p>
              {langue === 'english'
                ? 'Name of the Bank: Asly Banque en Ligne S.A.'
                : langue === 'portuguais'
                ? 'Nome do Banco: Asly Banque en Ligne S.A.'
                : 'Name der Bank: Asly Banque en Ligne S.A.'}
            </p>
            <p>
              {langue === 'english'
                ? 'Registered Address: Av.da República 57,1050-189 Lisboa, Portugal'
                : langue === 'portuguais'
                ? 'Endereço registrado: Av.da República 57,1050-189 Lisboa, Portugal'
                : 'Registrierte Adresse: Av.da República 57,1050-189 Lissabon, Portugal'}
            </p>
            <p>Email address: contact@aslyonline.com</p>
            <p>
              {langue === 'english'
                ? 'Terms of Service:'
                : langue === 'portuguais'
                ? 'Termos de Serviço:'
                : 'Nutzungsbedingungen:'}
            </p>
            <p>
              {langue === 'english'
                ? 'By accessing this website, you accept the General Conditions of Use of Asly Online Bank. Please read them carefully before continuing.'
                : langue === 'portuguais'
                ? 'Ao acessar este site, você aceita as Condições Gerais de Uso do Asly Banque en Ligne. Por favor, leia-os atentamente antes de continuar.'
                : 'Durch den Zugriff auf diese Website akzeptieren Sie die Allgemeinen Nutzungsbedingungen von Asly Online Bank. Bitte lesen Sie diese sorgfältig, bevor Sie fortfahren.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Intellectual property:'
                : langue === 'portuguais'
                ? 'Propriedade Intelectual:'
                : 'Geistiges Eigentum:'}
            </h2>
            <p>
              {langue === 'english'
                ? 'The content of this site, including but not limited to text, images, graphics, logos and other elements, is protected by intellectual property laws and is the exclusive property of Asly Banque en Ligne or its licensors license.'
                : langue === 'portuguais'
                ? 'O conteúdo deste site, incluindo, mas não se limitando a texto, imagens, gráficos, logotipos e outros elementos, é protegido por leis de propriedade intelectual e é propriedade exclusiva do Asly Banque en Ligne ou de seus licenciadores.'
                : 'Der Inhalt dieser Seite, einschließlich, aber nicht beschränkt auf Texte, Bilder, Grafiken, Logos und andere Elemente, ist durch geistige Eigentumsrechte geschützt und ist das ausschließliche Eigentum von Asly Banque en Ligne oder seinen Lizenzgebern.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Protection of personal data:'
                : langue === 'portuguais'
                ? 'Proteção de dados pessoais:'
                : 'Schutz personenbezogener Daten:'}
            </h2>
            <p>
              {langue === 'english'
                ? 'Asly Online Bank is committed to protecting the privacy of its users in accordance with its Privacy Policy. By using this site, you consent to the processing of your personal data as described in this policy.'
                : langue === 'portuguais'
                ? 'O Asly Banque en Ligne está comprometido em proteger a privacidade de seus usuários de acordo com sua Política de Privacidade. Ao usar este site, você consente com o processamento de seus dados pessoais conforme descrito nesta política.'
                : 'Asly Online Bank verpflichtet sich, die Privatsphäre seiner Benutzer gemäß seiner Datenschutzrichtlinie zu schützen. Durch die Nutzung dieser Website stimmen Sie der Verarbeitung Ihrer personenbezogenen Daten gemäß dieser Richtlinie zu.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Cookies:'
                : langue === 'portuguais'
                ? 'Cookies:'
                : 'Cookies:'}
            </h2>
            <p>
              {langue === 'english'
                ? 'This site uses cookies to improve user experience. By continuing to browse this site, you accept the use of cookies in accordance with our Cookies Policy.'
                : langue === 'portuguais'
                ? 'Este site utiliza cookies para melhorar a experiência do usuário. Ao continuar navegando neste site, você aceita o uso de cookies de acordo com nossa Política de Cookies.'
                : 'Diese Seite verwendet Cookies, um die Benutzererfahrung zu verbessern. Wenn Sie weiterhin auf dieser Seite surfen, akzeptieren Sie die Verwendung von Cookies gemäß unserer Cookie-Richtlinie.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'External links:'
                : langue === 'portuguais'
                ? 'Links externos:'
                : 'Externe Links:'}
            </h2>
            <p>
              {langue === 'english'
                ? 'This site may contain links to third party websites. Asly Online Bank assumes no responsibility for the content or privacy policy of these sites.'
                : langue === 'portuguais'
                ? 'Este site pode conter links para sites de terceiros. O Asly Banque en Ligne não assume responsabilidade pelo conteúdo ou política de privacidade desses sites.'
                : 'Diese Seite kann Links zu Websites Dritter enthalten. Asly Online Bank übernimmt keine Verantwortung für die Inhalte oder die Datenschutzrichtlinie dieser Seiten.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Applicable Law and Jurisdiction:'
                : langue === 'portuguais'
                ? 'Lei aplicável e jurisdição:'
                : 'Anwendbares Recht und Gerichtsbarkeit:'}
            </h2>
            <p>
              {langue === 'english'
                ? 'This legal notice is subject to German law. Any dispute arising from the use of this site will be subject to the exclusive jurisdiction of the courts of Berlin.'
                : langue === 'portuguais'
                ? 'Este aviso legal está sujeito à lei alemã. Qualquer disputa decorrente do uso deste site estará sujeita à jurisdição exclusiva dos tribunais de Berlim.'
                : 'Dieser rechtliche Hinweis unterliegt dem deutschen Recht. Alle Streitigkeiten, die sich aus der Nutzung dieser Seite ergeben, unterliegen der ausschließlichen Gerichtsbarkeit der Gerichte von Berlin.'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegalNotice
