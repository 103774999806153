import React from 'react'
import { Link } from 'react-router-dom'
import { insuranceList } from '../../data/insurance'
import './insurance.css'
import { useLangue } from '../../utils/hooks'

function Insurance() {
  const { langue } = useLangue()

  // Fonction pour traduire en fonction de la langue
  const getTranslation = (englishText, portugueseText, germanText) => {
    if (langue === 'english') {
      return englishText
    } else if (langue === 'portuguais') {
      return portugueseText
    } else if (langue === 'deutsch') {
      return germanText
    }
  }

  return (
    <div className="InsuranceWrapper" langue={langue}>
      <div className="insurance_top">
        <div className="insurance_top_color">
          <h1 className="insurance_top_h1">
            {getTranslation('Insurance', 'Seguro', 'Versicherung')}
          </h1>
          <div className="insurance_top_div">
            <Link to="/" className="home_link">
              {getTranslation('Home /', 'Início /', 'Startseite /')}
            </Link>
            <p>{getTranslation('Insurance', 'Seguro', 'Versicherung')}</p>
          </div>
        </div>
      </div>
      <div className="insurance_bottom">
        {insuranceList.map((it, index) => (
          <div className="insurance_bottom_item" key={`${it.title}-${index}`}>
            <img src={it.picture} alt="img" />
            <h3>
              {langue === 'english'
                ? it.title
                : langue === 'portuguais'
                ? it.title_pt
                : it.title_de}
            </h3>
            <p>
              {langue === 'english'
                ? it.description
                : langue === 'portuguais'
                ? it.description_pt
                : it.description_de}
            </p>
            <Link className="read_more_link" to="/insurance">
              {getTranslation('Read more →', 'Leia mais →', 'Mehr lesen →')}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Insurance
