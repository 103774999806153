import React from 'react'
import { Link } from 'react-router-dom'
import WhyChooseUs from '../../components/WhyChooseUs'
import NeedLoan from '../../components/NeedLoan'
import Team from '../../components/Team'
import './about.css'
import { useLangue } from '../../utils/hooks'

import AboutImg from '../../assets/about/about.jpg'

function About() {
  const { langue } = useLangue()

  return (
    <div className="aboutWrapper" langue={langue}>
      <div className="about_top">
        <div className="about_top_color">
          <h1 className="about_top_h1">
            {langue === 'english'
              ? 'About'
              : langue === 'portuguais'
              ? 'Sobre nós'
              : 'Über uns'}
          </h1>
          <div className="about_top_div">
            <Link to="/" className="about_link">
              {langue === 'english'
                ? 'Home /'
                : langue === 'portuguais'
                ? 'Início /'
                : 'Startseite /'}
            </Link>
            <p>
              {langue === 'english'
                ? 'About'
                : langue === 'portuguais'
                ? 'Sobre nós'
                : 'Über uns'}
            </p>
          </div>
        </div>
      </div>
      <div className="about_part_1">
        <div className="about_part_1_img">
          <img src={AboutImg} alt="About Asly Bank" />
        </div>
        <div className="about_part_1_des">
          <h1>
            {langue === 'english'
              ? 'Who are we ?'
              : langue === 'portuguais'
              ? 'Quem somos nós ?'
              : 'Wer sind wir ?'}
          </h1>
          <h3>
            {langue === 'english'
              ? 'Created in 2010, Asly Bank positions itself as a fully online bank specializing in offering different types of credit to its customers.'
              : langue === 'portuguais'
              ? 'Criado em 2010, o Asly Bank se posiciona como um banco totalmente online especializado em oferecer diferentes tipos de crédito aos seus clientes.'
              : 'Gegründet im Jahr 2010, positioniert sich Asly Bank als eine vollständig Online-Bank, die sich auf die Bereitstellung verschiedener Kreditarten für ihre Kunden spezialisiert.'}
          </h3>
          <p>
            {langue === 'english'
              ? 'We offer an innovative experience to lead your finances and take control of your wealth management. Securely and across all channels. Asly Bank is aimed at an active, mobile and independent clientele, wishing to take the reins of their personal financial management. We provide you with a complete range: everyday banking products, a savings solution, personalized services, as well as support available at any time on our platform.'
              : langue === 'portuguais'
              ? 'Oferecemos uma experiência inovadora para liderar suas finanças e controlar sua gestão de patrimônio. Com segurança e em todos os canais. O Asly Bank é destinado a uma clientela ativa, móvel e independente, que deseja assumir as rédeas de sua gestão financeira pessoal. Nós oferecemos uma gama completa: produtos bancários cotidianos, uma solução de poupança, serviços personalizados, além de suporte disponível a qualquer momento em nossa plataforma.'
              : 'Wir bieten eine innovative Erfahrung, um Ihre Finanzen zu führen und die Kontrolle über Ihre Vermögensverwaltung zu übernehmen. Sicher und über alle Kanäle hinweg. Asly Bank richtet sich an eine aktive, mobile und unabhängige Kundschaft, die die Zügel ihrer persönlichen Finanzverwaltung in die Hand nehmen möchte. Wir bieten Ihnen ein vollständiges Angebot: alltägliche Bankprodukte, eine Sparlösung, personalisierte Dienstleistungen sowie Unterstützung, die jederzeit auf unserer Plattform verfügbar ist.'}
          </p>
        </div>
      </div>
      <div>
        <WhyChooseUs />
      </div>
      <div>
        <NeedLoan />
      </div>
      <div>
        <Team />
      </div>
    </div>
  )
}

export default About
