import testimonials_1 from '../assets/testimonial/testimonials-1.jpg'
import testimonials_2 from '../assets/testimonial/testimonials-2.jpg'
import testimonials_3 from '../assets/testimonial/testimonials-3.jpg'
import testimonials_4 from '../assets/testimonial/testimonials-4.jpg'
import testimonials_5 from '../assets/testimonial/testimonials-5.jpg'

export const testimonialsList = [
  {
    name: 'Paul Dubois',
    profil: 'Teacher',
    description:
      'Getting a loan with Asly was a seamless and quick experience. Their professional team answered all my questions and made the process simple and efficient.',
    picture: testimonials_1,
    description_de:
      'Die Aufnahme eines Kredits bei Asly war eine schnelle und problemlose Erfahrung. Ihr professionelles Team beantwortete alle meine Fragen und machte den Prozess einfach und effizient.',
    profil_de: 'Lehrer',
    name_pt: 'Paul Dubois',
    profil_pt: 'Professor',
    description_pt:
      'Obter um empréstimo com a Asly foi uma experiência rápida e sem complicação. A equipe profissional respondeu todas as minhas perguntas e tornou o processo simples e eficiente.',
  },
  {
    name: 'Claire Martinez',
    profil: 'Architect',
    description:
      'Asly made my dream of owning a home come true. Their team guided me through every step of the loan process, providing exceptional service and great terms.',
    picture: testimonials_2,
    description_de:
      'Asly hat meinen Traum vom Eigenheim wahr gemacht. Ihr Team begleitete mich durch jeden Schritt des Darlehensprozesses und bot mir einen außergewöhnlichen Service und tolle Konditionen.',
    profil_de: 'Architekt',
    name_pt: 'Claire Martinez',
    profil_pt: 'Arquiteta',
    description_pt:
      'A Asly fez o meu sonho de ter uma casa própria se tornar realidade. A equipe me guiou em cada etapa do processo de empréstimo, oferecendo um serviço excepcional e ótimas condições.',
  },
  {
    name: 'Fatima Al-Mansoori',
    profil: 'Entrepreneur',
    description:
      'As a young entrepreneur, Asly offered me a tailor-made loan that stimulated the growth of my business. Their commitment to small businesses is remarkable.',
    picture: testimonials_3,
    description_de:
      'Als junger Unternehmer bot mir Asly ein maßgeschneidertes Darlehen, das das Wachstum meines Unternehmens förderte. Ihr Engagement für kleine Unternehmen ist bemerkenswert.',
    profil_de: 'Unternehmer',
    name_pt: 'Fatima Al-Mansoori',
    profil_pt: 'Empreendedora',
    description_pt:
      'Como jovem empreendedora, a Asly me ofereceu um empréstimo sob medida que estimulou o crescimento do meu negócio. O compromisso deles com pequenas empresas é notável.',
  },
  {
    name: 'James Harper',
    profil: 'IT consultant',
    description:
      'Asly was able to understand my financial needs. Thanks to their flexibility and competitive rates, I was able to consolidate my debts and save on interest.',
    picture: testimonials_4,
    description_de:
      'Asly war in der Lage, meine finanziellen Bedürfnisse zu verstehen. Dank ihrer Flexibilität und ihrer wettbewerbsfähigen Preise konnte ich meine Schulden konsolidieren und Zinsen sparen.',
    profil_de: 'IT-Consultant',
    name_pt: 'James Harper',
    profil_pt: 'Consultor de TI',
    description_pt:
      'A Asly foi capaz de entender minhas necessidades financeiras. Graças à sua flexibilidade e taxas competitivas, consegui consolidar minhas dívidas e economizar nos juros.',
  },
  {
    name: 'Alejandro Garcia',
    profil: 'Lawyer',
    description:
      "Asly's auto loan allowed me to buy the car of my dreams without hassle. The process was simple, and their team demonstrated exceptional professionalism.",
    picture: testimonials_5,
    description_de:
      'Der Autokredit von Asly hat es mir ermöglicht, das Auto meiner Träume ohne Probleme zu kaufen. Der Prozess war einfach, und das Team zeigte außergewöhnliche Professionalität.',
    profil_de: 'Rechtsanwalt',
    name_pt: 'Alejandro Garcia',
    profil_pt: 'Advogado',
    description_pt:
      'O empréstimo para automóveis da Asly me permitiu comprar o carro dos meus sonhos sem complicação. O processo foi simples, e a equipe demonstrou um profissionalismo excepcional.',
  },
]
