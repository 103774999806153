import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import './header.css'
import { useLangue } from '../../utils/hooks'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Visibility } from '@mui/icons-material'
const theme = createTheme({
  components: {
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          fontSize: '22px',
          color: 'gray',
          fontWeight: '600',
        },
        primary: {
          fontSize: '22px',
          fontWeight: '600',
          color: 'transparent', // Nécessaire pour activer le dégradé
          background: 'linear-gradient(to right, #1a1a40, #3a3a6d)', // Dégradé
          WebkitBackgroundClip: 'text', // Clip le texte au dégradé
          WebkitTextFillColor: 'transparent', // Supprime la couleur de remplissage
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 18,
          fontWeight: 'bold',
          height: '50px',
        },
      },
    },
  },
})
function Header() {
  const { langue } = useLangue()
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [isLoggedIn, setIsLoggedIn] = useState(token)
  const [isAdmin, setIsAdmin] = useState(false)
  const [mobileOpen, setMobileOpen] = useState(false)

  useEffect(() => {
    document.title = 'Asly'
    const userRole = localStorage.getItem('userRole')
    setIsAdmin(userRole === 'true')
  }, [])

  const handleLogout = () => {
    localStorage.clear()
    setIsLoggedIn(false)
    setIsAdmin(false)
    toggleDrawer(false)
    //navigate('/')
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setMobileOpen(open)
  }

  const drawerContent = (
    <List>
      <ListItem
        button
        component="a"
        href="/#loanForm"
        onClick={toggleDrawer(false)}
      >
        <ListItemText
          sx={{ fontSize: '18px', color: 'red', fontWeight: 'bold' }}
          primary={
            langue === 'english'
              ? 'Loan application'
              : langue === 'portuguais'
              ? 'Empréstimo rápido'
              : 'Kreditantrag'
          }
        />
      </ListItem>

      <ListItem button component="a" href="/" onClick={toggleDrawer(false)}>
        <ListItemText
          sx={{ fontSize: '18px', color: 'red', fontWeight: 'bold' }}
          primary={
            langue === 'english'
              ? 'Home'
              : langue === 'portuguais'
              ? 'Início'
              : 'Startseite'
          }
        />
      </ListItem>

      <ListItem
        button
        component="a"
        href="/services"
        onClick={toggleDrawer(false)}
      >
        <ListItemText
          primary={
            langue === 'english'
              ? 'Services'
              : langue === 'portuguais'
              ? 'Serviços'
              : 'Dienste'
          }
        />
      </ListItem>

      <ListItem
        button
        component={Link}
        to="/about"
        onClick={toggleDrawer(false)}
      >
        <ListItemText
          className="list-item"
          primary={
            langue === 'english'
              ? 'About'
              : langue === 'portuguais'
              ? 'Sobre'
              : 'Über uns'
          }
        />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/insurance"
        onClick={toggleDrawer(false)}
      >
        <ListItemText
          className="list-item"
          primary={
            langue === 'english'
              ? 'Insurance'
              : langue === 'portuguais'
              ? 'Seguro'
              : 'Versicherung'
          }
        />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/team"
        onClick={toggleDrawer(false)}
      >
        <ListItemText
          className="list-item"
          primary={
            langue === 'english'
              ? 'Team'
              : langue === 'portuguais'
              ? 'Equipe'
              : 'Team'
          }
        />
      </ListItem>

      <ListItem
        button
        component={Link}
        to="/contact"
        onClick={toggleDrawer(false)}
      >
        <ListItemText
          className="list-item"
          primary={
            langue === 'english'
              ? 'Contact'
              : langue === 'portuguais'
              ? 'Contato'
              : 'Kontakt'
          }
        />
      </ListItem>

      {!isLoggedIn && (
        <>
          <ListItem>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                backgroundColor: '#ffffff',
                color: 'gray',
                border: '1px solid transparent',
                borderColor: 'rgba(128, 128, 128, 0.3)',
                boxShadow: 'none',
                '&:hover': {
                  border: '1px solid transparent',
                  borderColor: 'rgba(128, 128, 128, 0.3)',
                  backgroundColor: '#ffffff',
                },
              }}
              component={Link}
              to="/login"
              onClick={toggleDrawer(false)}
            >
              {langue === 'english'
                ? 'Login'
                : langue === 'portuguais'
                ? 'Conexão'
                : 'Anmelden'}
            </Button>
          </ListItem>
          <ListItem>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                backgroundColor: 'blue',
                color: 'white',
                border: '1px solid transparent',
                '&:hover': {
                  border: '1px solid transparent',
                  backgroundColor: 'blue',
                },
              }}
              component="a"
              href="/#loanForm"
              onClick={toggleDrawer(false)}
            >
              {langue === 'english'
                ? 'Sign Up'
                : langue === 'portuguais'
                ? 'Registro'
                : 'Registrieren'}
            </Button>
          </ListItem>
        </>
      )}

      {isLoggedIn && (
        <>
          {isAdmin ? (
            <>
              <ListItem
                button
                component={Link}
                to="/display_users"
                onClick={toggleDrawer(false)}
              >
                <ListItemText
                  className="list-item"
                  primary={
                    langue === 'english'
                      ? 'Admin Dashboard'
                      : langue === 'portuguais'
                      ? 'Painel Admin'
                      : 'Admin-Dashboard'
                  }
                />
              </ListItem>
              {/**  <ListItem
                button
                component={Link}
                to="/manage_users"
                onClick={toggleDrawer(false)}
              >
                <ListItemText
                  className="list-item"
                  primary={
                    langue === 'english'
                      ? 'Manage Users'
                      : langue === 'portuguais'
                      ? 'Gerenciar Usuários'
                      : 'Benutzer verwalten'
                  }
                />
              </ListItem>*/}
            </>
          ) : (
            <>
              <ListItem
                button
                component={Link}
                to="/user_dashboard"
                onClick={toggleDrawer(false)}
              >
                <ListItemText
                  className="list-item"
                  primary={
                    langue === 'english'
                      ? 'User Dashboard'
                      : langue === 'portuguais'
                      ? 'Painel do Usuário'
                      : 'Benutzer-Dashboard'
                  }
                />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/profile"
                onClick={toggleDrawer(false)}
              >
                <ListItemText
                  className="list-item"
                  primary={
                    langue === 'english'
                      ? 'Profile'
                      : langue === 'portuguais'
                      ? 'Perfil'
                      : 'Profil'
                  }
                />
              </ListItem>
            </>
          )}

          <ListItem onClick={handleLogout}>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                backgroundColor: 'blue',
                color: 'white',
                border: '1px solid transparent',
                '&:hover': {
                  border: '1px solid transparent',
                  backgroundColor: 'blue',
                },
              }}
              component={Link}
              onClick={toggleDrawer(false)}
            >
              {langue === 'english'
                ? 'Logout'
                : langue === 'portuguais'
                ? 'Sair'
                : 'Abmelden'}
            </Button>
          </ListItem>
        </>
      )}
    </List>
  )

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed" // Garde la Navbar fixe
          sx={{
            width: '100%',
            zIndex: 1200,
          }}
        >
          <Toolbar>
            {/* Logo visible sur mobile et desktop */}
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <a
                href="/"
                className="logo-link"
                style={{
                  textDecoration: 'none',
                  color: '#ffffff',
                  fontSize: '27px',
                  fontWeight: '600',
                }}
              >
                A-SLY
              </a>
            </Typography>

            {/* Mobile Menu Icon */}
            <IconButton
              edge="end"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' },
              }}
            >
              {mobileOpen ? (
                <CloseIcon style={{ color: 'white', fill: 'white' }} />
              ) : (
                <MenuIcon style={{ color: 'white', fill: 'white' }} />
              )}
            </IconButton>

            {/* Desktop Links */}
            <Box
              sx={{
                display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
                gap: 2,
              }}
            >
              <div className="part_1">
                {!isLoggedIn && !isAdmin && (
                  <>
                    <a href="/" className="desktop-link">
                      {langue === 'english'
                        ? 'Home'
                        : langue === 'portuguais'
                        ? 'Início'
                        : 'Startseite'}
                    </a>
                    {/* A-sly Menu with Submenu */}
                    <Link
                      to="/"
                      className="link asly clicOnAsly desktop-link"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {langue === 'english' ? 'A-sly' : 'A-sly'}
                      <div className="sousmenu">
                        <Link className="link" to="/about">
                          {langue === 'english'
                            ? 'About'
                            : langue === 'portuguais'
                            ? 'Sobre'
                            : 'Über uns'}
                        </Link>
                        <Link className="link" to="/insurance">
                          {langue === 'english'
                            ? 'Insurance'
                            : langue === 'portuguais'
                            ? 'Seguro'
                            : 'Versicherung'}
                        </Link>
                        <Link className="link" to="/team">
                          {langue === 'english'
                            ? 'Team'
                            : langue === 'portuguais'
                            ? 'Equipe'
                            : 'Team'}
                        </Link>
                      </div>
                    </Link>

                    <a href="/services" className="desktop-link">
                      {langue === 'english'
                        ? 'Services'
                        : langue === 'portuguais'
                        ? 'Serviços'
                        : 'Dienste'}
                    </a>
                    <Link to="/contact" className="desktop-link">
                      {langue === 'english'
                        ? 'Contact'
                        : langue === 'portuguais'
                        ? 'Contato'
                        : 'Kontakt'}
                    </Link>
                  </>
                )}

                {isLoggedIn && (
                  <>
                    {isAdmin ? (
                      <>
                        <Link></Link>
                        <Link
                          to="/display_users"
                          style={{ float: 'right', marginRight: '35px' }}
                          className="desktop-link"
                        >
                          Dashboard
                        </Link>
                        {/*<Link to="/manage_users" className="desktop-link">
                        {langue === 'english'
                          ? 'Manage Users'
                          : langue === 'portuguais'
                          ? 'Gerenciar Usuários'
                          : 'Benutzer verwalten'}
                      </Link>*/}
                      </>
                    ) : (
                      <>
                        <Link to="/user_dashboard" className="desktop-link">
                          {langue === 'english'
                            ? 'User Dashboard'
                            : langue === 'portuguais'
                            ? 'Painel do Usuário'
                            : 'Benutzer-Dashboard'}
                        </Link>
                        <Link to="/profile" className="desktop-link">
                          {langue === 'english'
                            ? 'Profile'
                            : langue === 'portuguais'
                            ? 'Perfil'
                            : 'Profil'}
                        </Link>
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="part_2">
                {!isLoggedIn && (
                  <>
                    <Link to="/login" className="desktop-link">
                      {langue === 'english'
                        ? 'Connection'
                        : langue === 'portuguais'
                        ? 'Conexão'
                        : 'Anmelden'}
                    </Link>
                    <a href="/#loanForm" className="desktop-link">
                      {langue === 'english'
                        ? 'Registration'
                        : langue === 'portuguais'
                        ? 'Registro'
                        : 'Registrieren'}
                    </a>
                  </>
                )}

                {isLoggedIn && (
                  <>
                    <a href="/" className="desktop-link" onClick={handleLogout}>
                      {langue === 'english'
                        ? 'Logout'
                        : langue === 'portuguais'
                        ? 'Sair'
                        : 'Abmelden'}
                    </a>
                  </>
                )}
              </div>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Mobile Drawer */}
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={toggleDrawer(false)}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            width: '70%',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: '70%',
              marginTop: '57px',
              paddingLeft: '15px',
              paddingRight: '15px',
              backgroundColor: '#f1f1f1',
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </Box>
    </ThemeProvider>
  )
}

export default Header
