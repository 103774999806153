import React, { useContext } from 'react'
import { LoginContext } from '../../utils/context'
import { useLangue } from '../../utils/hooks'

import './accountData.css'

function AccountData() {
  const { langue } = useLangue()
  const { isLoggedIn } = useContext(LoginContext)

  return (
    isLoggedIn && (
      <div className="data_container" langue={langue}>
        <div className="datas">
          <h2>
            {langue === 'english'
              ? 'Account Number :'
              : langue === 'portuguais'
              ? 'Número da Conta :'
              : 'Kontonummer :'}
          </h2>
          <p>4539 0979 184</p>
        </div>
        <div className="datas">
          <h2>
            {langue === 'english'
              ? 'NIB :'
              : langue === 'portuguais'
              ? 'NIB :'
              : 'NIB :'}
          </h2>
          <p>0033 0000 4539 0979 184</p>
        </div>
        <div className="datas">
          <h2>
            {langue === 'english'
              ? 'IBAN :'
              : langue === 'portuguais'
              ? 'IBAN :'
              : 'IBAN :'}
          </h2>
          <p>AS50 0033 0000 4539 0979 184</p>
        </div>
        <div className="datas">
          <h2>
            {langue === 'english'
              ? 'BIC / SWIFT :'
              : langue === 'portuguais'
              ? 'BIC / SWIFT :'
              : 'BIC / SWIFT :'}
          </h2>
          <p>
            BCOMASPL
            {langue === 'english'
              ? 'BCOMASPL'
              : langue === 'portuguais'
              ? 'PTOM0058'
              : 'DEOM0058'}
          </p>
        </div>
      </div>
    )
  )
}

export default AccountData
