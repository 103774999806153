import blog_1 from '../assets/insurance/blog-1.jpg'
import blog_2 from '../assets/insurance/blog-2.jpg'
import blog_3 from '../assets/insurance/blog-3.jpg'
import blog_4 from '../assets/insurance/blog-4.jpg'
import blog_5 from '../assets/insurance/blog-5.jpg'
import blog_6 from '../assets/insurance/blog-6.jpg'

export const insuranceList = [
  {
    title: 'Death Disability Insurance (ADI)',
    description:
      'Death and Disability Insurance (ADI) represents crucial security for the borrower and their loved ones. In the event of death or permanent disability, this insurance covers the repayment of the loan balance, thus alleviating the financial burden that could weigh on the family in these difficult times. It provides peace of mind by ensuring that the debt incurred does not become an additional financial burden in tragic circumstances.',
    picture: blog_1,
    title_pt: 'Seguro de Morte e Incapacidade (ADI)',
    description_pt:
      'O Seguro de Morte e Incapacidade (ADI) oferece uma segurança crucial para o mutuário e seus entes queridos. Em caso de falecimento ou invalidez permanente, este seguro cobre o pagamento do saldo do empréstimo, aliviando o fardo financeiro que poderia recair sobre a família nestes tempos difíceis. Ele proporciona tranquilidade, garantindo que a dívida contraída não se torne um fardo financeiro adicional em circunstâncias trágicas.',
    title_de: 'Invaliditätsversicherung im Todesfall (ADI)',
    description_de:
      'Die Todesfall- und Invaliditätsversicherung (ADI) bietet den Kreditnehmern und ihren Familien eine wichtige Sicherheit. Im Todesfall oder bei dauerhafter Invalidität deckt diese Versicherung die Rückzahlung des Darlehenssaldos und erleichtert so die finanzielle Belastung, die auf der Familie in diesen schwierigen Zeiten lasten könnte. Die Versicherung sorgt dafür, dass die aufgenommenen Schulden nicht zu einer zusätzlichen finanziellen Belastung werden, wenn die Umstände tragisch sind.',
  },
  {
    title: 'Job Loss Insurance',
    description:
      'Job Loss insurance offers protection in times of professional uncertainty. In the event of involuntary unemployment, it intervenes by covering the monthly loan payments for a predetermined period. This gives the borrower a financial buffer period, allowing a smoother transition to new professional opportunities without compromising financial stability.',
    picture: blog_2,
    title_pt: 'Seguro de Perda de Emprego',
    description_pt:
      'O Seguro de Perda de Emprego oferece proteção em momentos de incerteza profissional. Em caso de desemprego involuntário, ele intervém cobrindo os pagamentos mensais do empréstimo por um período predeterminado. Isso dá ao mutuário um período de respiro financeiro, permitindo uma transição mais tranquila para novas oportunidades profissionais sem comprometer a estabilidade financeira.',
    title_de: 'Versicherung gegen Arbeitsausfall',
    description_de:
      'Die Arbeitsplatzverlustversicherung bietet Schutz in Zeiten beruflicher Unsicherheit. Im Falle einer unfreiwilligen Arbeitslosigkeit springt sie ein, indem sie die monatlichen Darlehenszahlungen für einen vorher festgelegten Zeitraum übernimmt. Dies verschafft dem Kreditnehmer einen finanziellen Puffer, der einen reibungsloseren Übergang zu neuen beruflichen Möglichkeiten ermöglicht, ohne die finanzielle Stabilität zu gefährden.',
  },
  {
    title: 'Critical Illness Insurance',
    description:
      'Critical Illness insurance provides financial security in the event of diagnosis of a serious illness stipulated in the contract. By offering a lump sum payment or covering monthly loan payments, it aims to alleviate the financial pressures associated with medical costs and loss of income resulting from serious illness. It is a lifeline that allows the borrower to focus on recovery without worrying about the immediate financial implications.',
    picture: blog_3,
    title_pt: 'Seguro de Doenças Críticas',
    description_pt:
      'O Seguro de Doenças Críticas oferece segurança financeira no caso de diagnóstico de uma doença grave estipulada no contrato. Ao oferecer um pagamento único ou cobrir os pagamentos mensais do empréstimo, visa aliviar as pressões financeiras associadas aos custos médicos e à perda de rendimento resultante de doenças graves. É uma tábua de salvação que permite ao mutuário focar-se na recuperação sem se preocupar com as implicações financeiras imediatas.',
    title_de: 'Versicherung gegen kritische Krankheiten',
    description_de:
      'Die Critical-Illness-Versicherung bietet finanzielle Sicherheit für den Fall, dass eine im Vertrag festgelegte schwere Krankheit diagnostiziert wird. Durch die Zahlung eines Pauschalbetrags oder die Deckung der monatlichen Darlehensraten soll sie den finanziellen Druck, der mit den medizinischen Kosten und dem Einkommensverlust infolge einer schweren Krankheit verbunden ist, abmildern. Es ist ein Rettungsanker, der es dem Kreditnehmer ermöglicht, sich auf die Genesung zu konzentrieren, ohne sich um die unmittelbaren finanziellen Auswirkungen zu kümmern.',
  },
  {
    title: 'Permanent Total Disability Insurance (IPT)',
    description:
      'Permanent Total Disability insurance covers permanent incapacity to work. It guarantees repayment of the loan balance, providing crucial financial protection to the borrower and their family. This insurance provides peace of mind by ensuring that, even if you are unable to earn an income, financial commitments are taken care of.',
    picture: blog_4,
    title_pt: 'Seguro de Incapacidade Permanente Total (IPT)',
    description_pt:
      'O seguro de incapacidade permanente total cobre a incapacidade permanente para o trabalho. Ele garante o pagamento do saldo do empréstimo, oferecendo uma proteção financeira crucial para o mutuário e sua família. Este seguro proporciona tranquilidade, garantindo que, mesmo que você não consiga gerar uma renda, os compromissos financeiros sejam cumpridos.',
    title_de: 'Versicherung gegen dauerhafte Vollinvalidität (IPT)',
    description_de:
      'Die Versicherung für dauerhafte Vollinvalidität deckt eine dauerhafte Arbeitsunfähigkeit ab. Sie garantiert die Rückzahlung des Darlehenssaldos und bietet dem Darlehensnehmer und seiner Familie einen entscheidenden finanziellen Schutz. Diese Versicherung gibt Ihnen Sicherheit, denn sie gewährleistet, dass Ihre finanziellen Verpflichtungen auch dann erfüllt werden, wenn Sie nicht in der Lage sind, ein Einkommen zu erzielen.',
  },
  {
    title: 'Temporary Total Incapacity Insurance (ITT)',
    description:
      'Total Temporary Disability insurance is a lifeline in the event of temporary incapacity resulting from illness or injury. By covering the monthly loan payments during this period, it provides a financial safety net, allowing the borrower to recover without experiencing the financial pressures associated with being temporarily unable to work.',
    picture: blog_5,
    title_pt: 'Seguro de Incapacidade Total Temporária (ITT)',
    description_pt:
      'O seguro de incapacidade total temporária é um auxílio no caso de incapacidade temporária resultante de doença ou lesão. Ao cobrir os pagamentos mensais do empréstimo durante esse período, oferece uma rede de segurança financeira, permitindo que o mutuário se recupere sem sofrer a pressão financeira associada à incapacidade temporária de trabalhar.',
    title_de: 'Vorübergehende Vollinvaliditätsversicherung (ITT)',
    description_de:
      'Die Versicherung gegen vorübergehende Vollinvalidität ist ein Rettungsanker bei vorübergehender Arbeitsunfähigkeit infolge einer Krankheit oder eines Unfalls. Indem sie die monatlichen Kreditraten während dieses Zeitraums abdeckt, bietet sie ein finanzielles Sicherheitsnetz, das es dem Kreditnehmer ermöglicht, sich zu erholen, ohne den finanziellen Druck zu spüren, der mit einer vorübergehenden Arbeitsunfähigkeit verbunden ist.',
  },
  {
    title: 'Partial Unemployment Insurance',
    description:
      'Partial Unemployment insurance intervenes during periods of reduced working hours or partial unemployment. It covers a portion of the loan payments, providing financial support during these periods of professional transition. This insurance is designed to help the borrower maintain financial stability even when their income is partially affected by difficult economic circumstances.',
    picture: blog_6,
    title_pt: 'Seguro de Desemprego Parcial',
    description_pt:
      'O seguro de desemprego parcial intervém durante períodos de redução de jornada de trabalho ou desemprego parcial. Ele cobre uma parte dos pagamentos do empréstimo, oferecendo apoio financeiro durante esses períodos de transição profissional. Este seguro foi projetado para ajudar o mutuário a manter a estabilidade financeira, mesmo quando sua renda for parcialmente afetada por circunstâncias econômicas difíceis.',
    title_de: 'Teilweise Arbeitslosenversicherung',
    description_de:
      'Die Teilarbeitslosenversicherung greift in Zeiten von Kurzarbeit oder Teilarbeitslosigkeit ein. Sie deckt einen Teil der Kreditzahlungen ab und bietet so finanzielle Unterstützung in diesen Zeiten des beruflichen Übergangs. Diese Versicherung soll den Darlehensnehmern helfen, ihre finanzielle Stabilität zu erhalten, auch wenn ihr Einkommen teilweise durch schwierige wirtschaftliche Umstände beeinträchtigt ist.',
  },
]
