import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import './needLoan.css'
import { LoginContext } from '../../utils/context'
import { useLangue } from '../../utils/hooks'

function NeedLoan(props) {
  const { langue } = useLangue()
  const { isLoggedIn } = useContext(LoginContext)

  return (
    <div className="home_part_3" langue={langue}>
      <div className="home_part_3_color">
        <h2 className="home_part_3_h2">
          {langue === 'english'
            ? 'Do you need a loan for your project?'
            : langue === 'portuguais'
            ? 'Precisa de um empréstimo para o seu projeto?'
            : 'Brauchst du einen Kredit für dein Projekt?'}
        </h2>
        <p className="home_part_3_p">
          {langue === 'english'
            ? 'Asly grants loans, from €5,000 to €70,000,000 in less than 72 hours over a repayment period of 01 to 30 years, with an interest rate of 2.5% per year.'
            : langue === 'portuguais'
            ? 'Asly concede empréstimos, de €5.000 a €70.000.000 em menos de 72 horas, durante um período de reembolso de 01 a 30 anos, com uma taxa de juros de 2,5% ao ano.'
            : 'Asly gewährt Darlehen von 5.000 € bis 70.000.000 € in weniger als 72 Stunden mit einer Rückzahlungsfrist von 1 bis 30 Jahren und einem Zinssatz von 2,5 % pro Jahr.'}
        </p>
        {!isLoggedIn ? (
          <div className="home_part_3_btn_div">
            <Link
              href=""
              onClick={props.scrollToLoan}
              className="home_part_3_btn"
            >
              {langue === 'english'
                ? 'Request a loan now'
                : langue === 'portuguais'
                ? 'Pedir um empréstimo agora'
                : 'Fordere jetzt ein Darlehen an'}
            </Link>
          </div>
        ) : (
          <div className="home_part_3_btn_div">
            <Link to="/loan" className="home_part_3_btn">
              {langue === 'english'
                ? 'Start now'
                : langue === 'portuguais'
                ? 'Comece agora'
                : 'Jetzt starten'}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default NeedLoan
