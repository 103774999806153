import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './signup.css'
import { useLangue } from '../../utils/hooks'

function Signup() {
  const { langue } = useLangue()
  const [formData, setFormData] = useState({
    civility: '',
    lastName: '',
    firstName: '',
    birthday: '',
    country: '',
    phone: '',
    marital: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    occupation: '',
    city: '',
    address: '',
  })

  const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const checkEmptyData = (data) => {
    for (const key in data) {
      if (data[key] === '') {
        setError(
          langue === 'english' ? 'No empty fields !' : 'Nenhum campo vazio !'
        )
        return false
      }

      if (data.email !== data.confirmEmail) {
        setError(
          langue === 'english' ? 'Incorrect Email !' : 'E-mail incorreto !'
        )
        return false
      }
      if (data.password !== data.confirmPassword) {
        setError(
          langue === 'english' ? 'Incorrect password!' : 'Senha incorreta!'
        )
        return false
      }
    }
    return true
  }

  const registerUsers = () => {
    if (checkEmptyData(formData)) {
      fetch(`${process.env.REACT_APP_BASE_URL}/auth/signup`, {
        // fetch(`http://127.0.0.1:8001/api/auth/signup`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then(() => navigate('/account_validation'))
        .catch((error) => {
          // Handle fetch error
          setError(
            langue === 'english'
              ? 'An error occurred while signing in.'
              : 'Ocorreu um erro durante o registro.'
          )
          navigate('/signup')
        })
    }
  }

  return (
    <div className="signupContainer" langue={langue}>
      <div className="signup_sub_container">
        <div className="signup_left">
          <h1 className="signup-title-1">Asly Bank</h1>
          <h2 className="signup-title-2">
            {langue === 'english'
              ? 'For your satisfaction'
              : 'Para sua satisfação'}
          </h2>
          <h3 className="signup-title-3">
            {langue === 'english'
              ? 'Welcome to Asly Bank!'
              : 'Bem-vindo ao Asly Bank!'}
          </h3>

          <p className="signup-title-message">
            {langue === 'english' ? (
              <>
                We are delighted to welcome you among us. Your new account opens
                the doors to our banking services adapted to your needs. Explore
                our online platform, carry out your transactions securely, and
                benefit from exclusive advantages. Need help ? Our team is here
                for you. Thank you for trusting Fyno Bank. We are here to help
                you achieve your financial goals. <br />
                The Asly Bank team
              </>
            ) : (
              <>
                Estamos encantados em recebê-lo entre nós. Sua nova conta abre
                as portas para nossos serviços bancários adaptados às suas
                necessidades. Explore nossa plataforma online, realize suas
                transações com segurança e beneficie-se de vantagens exclusivas.
                Precisa de ajuda? Nossa equipe está aqui para você. Obrigado por
                confiar no Fyno Bank. Estamos aqui para ajudá-lo a alcançar seus
                objetivos financeiros. <br />A equipe do Asly Bank
              </>
            )}
          </p>
        </div>
        <div className="signup_form">
          <form onSubmit={(e) => e.preventDefault()}>
            <h1 className="register-title-4">
              {langue === 'english' ? 'REGISTRATION' : 'REGISTRO'}
            </h1>
            <div className="form_content">
              {/* begining part 1 of register's informations. */}

              <div className="register_part_1">
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'Civility' : 'Civilidade'}
                  </label>
                  <select
                    type="text"
                    name="civility"
                    value={formData.civility}
                    onChange={handleInputChange}
                    className="register-input input_select"
                  >
                    <option value="" disabled>
                      {langue === 'english' ? 'Civility' : 'Civilidade'}
                    </option>
                    <option value="Sir">
                      {langue === 'english' ? 'Sir' : 'Senhor'}
                    </option>
                    <option value="Madam">
                      {langue === 'english' ? 'Madam' : 'Madame'}
                    </option>
                  </select>
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'Last Name' : 'Último Nome'}
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'First Name' : 'Primeiro Nome'}
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'Birthday' : 'Data de Nascimento'}
                  </label>
                  <input
                    type="date"
                    // placeholder="jj/mm/aaaa"
                    name="birthday"
                    value={formData.birthday}
                    onChange={handleInputChange}
                    className="register-input birthday_input"
                  />
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'Country' : 'País'}
                  </label>
                  <select
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    className="register-input input_select"
                  >
                    <option
                      value=""
                      disabled
                      selected
                      hidden
                      class="text-muted "
                    >
                      {langue === 'english' ? 'Country/Region' : 'País/Região'}
                    </option>
                    <option value="PT">
                      {langue === 'english' ? 'Portugal' : 'Portugal'}
                    </option>
                    <option disabled value="">
                      ---
                    </option>
                    <option value="AX">
                      {langue === 'english' ? 'Aland Islands' : 'Ilhas Aland'}
                    </option>
                    <option value="AF">
                      {langue === 'english' ? 'Afghanistan' : 'Afeganistão'}
                    </option>
                    <option value="AL">
                      {langue === 'english' ? 'Albania' : 'Albânia'}
                    </option>
                    <option value="DZ">
                      {langue === 'english' ? 'Algeria' : 'Argélia'}
                    </option>
                    <option value="AD">
                      {langue === 'english' ? 'Andorra' : 'Andorra'}
                    </option>
                    <option value="AO">
                      {langue === 'english' ? 'Angola' : 'Angola'}
                    </option>
                    <option value="AI">
                      {langue === 'english' ? 'Anguilla' : 'Anguilla'}
                    </option>
                    <option value="AQ">
                      {langue === 'english' ? 'Antarctica' : 'Antártica'}
                    </option>
                    <option value="AG">
                      {langue === 'english'
                        ? 'Antigua and Barbuda'
                        : 'Antígua e Barbuda'}
                    </option>
                    <option value="AR">
                      {langue === 'english' ? 'Argentina' : 'Argentina'}
                    </option>
                    <option value="AM">
                      {langue === 'english' ? 'Armenia' : 'Armênia'}
                    </option>
                    <option value="AW">
                      {langue === 'english' ? 'Aruba' : 'Aruba'}
                    </option>
                    <option value="AU">
                      {langue === 'english' ? 'Australia' : 'Austrália'}
                    </option>
                    <option value="AT">
                      {langue === 'english' ? 'Austria' : 'Áustria'}
                    </option>
                    <option value="AZ">
                      {langue === 'english' ? 'Azerbaijan' : 'Azerbaijão'}
                    </option>
                    <option value="BS">
                      {langue === 'english' ? 'Bahamas' : 'Bahamas'}
                    </option>
                    <option value="BH">
                      {langue === 'english' ? 'Bahrain' : 'Bahrain'}
                    </option>
                    <option value="BD">
                      {langue === 'english' ? 'Bangladesh' : 'Bangladesh'}
                    </option>
                    <option value="BB">
                      {langue === 'english' ? 'Barbados' : 'Barbados'}
                    </option>
                    <option value="BY">
                      {langue === 'english' ? 'Belarus' : 'Bielorrússia'}
                    </option>
                    <option value="BE">
                      {langue === 'english' ? 'Belgium' : 'Bélgica'}
                    </option>
                    <option value="BZ">
                      {langue === 'english' ? 'Belize' : 'Belize'}
                    </option>
                    <option value="BJ">
                      {langue === 'english' ? 'Benin' : 'Benin'}
                    </option>
                    <option value="BM">
                      {langue === 'english' ? 'Bermuda' : 'Bermudas'}
                    </option>
                    <option value="BT">
                      {langue === 'english' ? 'Bhutan' : 'Butão'}
                    </option>
                    <option value="BO">
                      {langue === 'english' ? 'Bolivia' : 'Bolívia'}
                    </option>
                    <option value="BA">
                      {langue === 'english'
                        ? 'Bosnia and Herzegovina'
                        : 'Bósnia e Herzegovina'}
                    </option>
                    <option value="BW">
                      {langue === 'english' ? 'Botswana' : 'Botsuana'}
                    </option>
                    <option value="BV">
                      {langue === 'english' ? 'Bouvet Island' : 'Ilha Bouvet'}
                    </option>
                    <option value="BR">
                      {langue === 'english' ? 'Brazil' : 'Brasil'}
                    </option>
                    <option value="IO">
                      {langue === 'english'
                        ? 'British Indian Ocean Territory'
                        : 'Território Britânico do Oceano Índico'}
                    </option>
                    <option value="VG">
                      {langue === 'english'
                        ? 'British Virgin Islands'
                        : 'Ilhas Virgens Britânicas'}
                    </option>
                    <option value="BN">
                      {langue === 'english' ? 'Brunei' : 'Brunei'}
                    </option>
                    <option value="BG">
                      {langue === 'english' ? 'Bulgaria' : 'Bulgária'}
                    </option>
                    <option value="BF">
                      {langue === 'english' ? 'Burkina Faso' : 'Burkina Faso'}
                    </option>
                    <option value="BI">
                      {langue === 'english' ? 'Burundi' : 'Burundi'}
                    </option>
                    <option value="KH">
                      {langue === 'english' ? 'Cambodia' : 'Camboja'}
                    </option>
                    <option value="CM">
                      {langue === 'english' ? 'Cameroon' : 'Camarões'}
                    </option>
                    <option value="CA">
                      {langue === 'english' ? 'Canada' : 'Canadá'}
                    </option>
                    <option value="CV">
                      {langue === 'english' ? 'Cape Verde' : 'Cabo Verde'}
                    </option>
                    <option value="KY">
                      {langue === 'english' ? 'Cayman Islands' : 'Ilhas Cayman'}
                    </option>
                    <option value="CF">
                      {langue === 'english'
                        ? 'Central African Republic'
                        : 'República Centro-Africana'}
                    </option>
                    <option value="TD">
                      {langue === 'english' ? 'Chad' : 'Chade'}
                    </option>
                    <option value="CL">
                      {langue === 'english' ? 'Chile' : 'Chile'}
                    </option>
                    <option value="CN">
                      {langue === 'english' ? 'China' : 'China'}
                    </option>
                    <option value="CX">
                      {langue === 'english'
                        ? 'Christmas Island'
                        : 'Ilha Christmas'}
                    </option>
                    <option value="CC">
                      {langue === 'english'
                        ? 'Cocos (Keeling) Islands'
                        : 'Ilhas Cocos (Keeling)'}
                    </option>
                    <option value="CO">
                      {langue === 'english' ? 'Colombia' : 'Colômbia'}
                    </option>
                    <option value="KM">
                      {langue === 'english' ? 'Comoros' : 'Comores'}
                    </option>
                    <option value="CG">
                      {langue === 'english'
                        ? 'Congo (Brazzaville)'
                        : 'Congo (Brazzaville)'}
                    </option>
                    <option value="CD">
                      {langue === 'english'
                        ? 'Congo (Kinshasa)'
                        : 'Congo (Kinshasa)'}
                    </option>
                    <option value="CK">
                      {langue === 'english' ? 'Cook Islands' : 'Ilhas Cook'}
                    </option>
                    <option value="CR">
                      {langue === 'english' ? 'Costa Rica' : 'Costa Rica'}
                    </option>
                    <option value="HR">
                      {langue === 'english' ? 'Croatia' : 'Croácia'}
                    </option>
                    <option value="CU">
                      {langue === 'english' ? 'Cuba' : 'Cuba'}
                    </option>
                    <option value="CW">
                      {langue === 'english' ? 'CuraÇao' : 'Curação'}
                    </option>
                    <option value="CY">
                      {langue === 'english' ? 'Cyprus' : 'Chipre'}
                    </option>
                    <option value="CZ">
                      {langue === 'english'
                        ? 'Czech Republic'
                        : 'República Tcheca'}
                    </option>
                    <option value="DK">
                      {langue === 'english' ? 'Denmark' : 'Dinamarca'}
                    </option>
                    <option value="DJ">
                      {langue === 'english' ? 'Djibouti' : 'Djibouti'}
                    </option>
                    <option value="DM">
                      {langue === 'english' ? 'Dominica' : 'Dominica'}
                    </option>
                    <option value="DO">
                      {langue === 'english'
                        ? 'Dominican Republic'
                        : 'República Dominicana'}
                    </option>
                    <option value="EC">
                      {langue === 'english' ? 'Ecuador' : 'Equador'}
                    </option>
                    <option value="EG">
                      {langue === 'english' ? 'Egypt' : 'Egito'}
                    </option>
                    <option value="SV">
                      {langue === 'english' ? 'El Salvador' : 'El Salvador'}
                    </option>
                    <option value="GQ">
                      {langue === 'english'
                        ? 'Equatorial Guinea'
                        : 'Guiné Equatorial'}
                    </option>
                    <option value="ER">
                      {langue === 'english' ? 'Eritrea' : 'Eritreia'}
                    </option>
                    <option value="EE">
                      {langue === 'english' ? 'Estonia' : 'Estônia'}
                    </option>
                    <option value="ET">
                      {langue === 'english' ? 'Ethiopia' : 'Etiópia'}
                    </option>
                    <option value="FK">
                      {langue === 'english'
                        ? 'Falkland Islands'
                        : 'Ilhas Falkland'}
                    </option>
                    <option value="FO">
                      {langue === 'english' ? 'Faroe Islands' : 'Ilhas Faroe'}
                    </option>
                    <option value="FJ">
                      {langue === 'english' ? 'Fiji' : 'Fiji'}
                    </option>
                    <option value="FI">
                      {langue === 'english' ? 'Finland' : 'Finlândia'}
                    </option>
                    <option value="FR">
                      {langue === 'english' ? 'France' : 'França'}
                    </option>
                    <option value="GF">
                      {langue === 'english'
                        ? 'French Guiana'
                        : 'Guiana Francesa'}
                    </option>
                    <option value="PF">
                      {langue === 'english'
                        ? 'French Polynesia'
                        : 'Polinésia Francesa'}
                    </option>
                    <option value="TF">
                      {langue === 'english'
                        ? 'French Southern Territories'
                        : 'Territórios Franceses do Sul'}
                    </option>
                    <option value="GA">
                      {langue === 'english' ? 'Gabon' : 'Gabão'}
                    </option>
                    <option value="GM">
                      {langue === 'english' ? 'Gambia' : 'Gâmbia'}
                    </option>
                    <option value="GE">
                      {langue === 'english' ? 'Georgia' : 'Geórgia'}
                    </option>
                    <option value="DE">
                      {langue === 'english' ? 'Germany' : 'Alemanha'}
                    </option>
                    <option value="GH">
                      {langue === 'english' ? 'Ghana' : 'Gana'}
                    </option>
                    <option value="GI">
                      {langue === 'english' ? 'Gibraltar' : 'Gibraltar'}
                    </option>
                    <option value="GR">
                      {langue === 'english' ? 'Greece' : 'Grécia'}
                    </option>
                    <option value="GL">
                      {langue === 'english' ? 'Greenland' : 'Groenlândia'}
                    </option>
                    <option value="GD">
                      {langue === 'english' ? 'Grenada' : 'Granada'}
                    </option>
                    <option value="GP">
                      {langue === 'english' ? 'Guadeloupe' : 'Guadalupe'}
                    </option>
                    <option value="GT">
                      {langue === 'english' ? 'Guatemala' : 'Guatemala'}
                    </option>
                    <option value="GG">
                      {langue === 'english' ? 'Guernsey' : 'Guernsey'}
                    </option>
                    <option value="GN">
                      {langue === 'english' ? 'Guinea' : 'Guiné'}
                    </option>
                    <option value="GW">
                      {langue === 'english' ? 'Guinea-Bissau' : 'Guiné-Bissau'}
                    </option>
                    <option value="GY">
                      {langue === 'english' ? 'Guyana' : 'Guiana'}
                    </option>
                    <option value="HT">
                      {langue === 'english' ? 'Haiti' : 'Haiti'}
                    </option>
                    <option value="HM">
                      {langue === 'english'
                        ? 'Heard Island and McDonald Islands'
                        : 'Ilhas Heard e McDonald'}
                    </option>
                    <option value="HN">
                      {langue === 'english' ? 'Honduras' : 'Honduras'}
                    </option>
                    <option value="HK">
                      {langue === 'english' ? 'Hong Kong' : 'Hong Kong'}
                    </option>
                    <option value="HU">
                      {langue === 'english' ? 'Hungary' : 'Hungria'}
                    </option>
                    <option value="IS">
                      {langue === 'english' ? 'Iceland' : 'Islândia'}
                    </option>
                    <option value="IN">
                      {langue === 'english' ? 'India' : 'Índia'}
                    </option>
                    <option value="ID">
                      {langue === 'english' ? 'Indonesia' : 'Indonésia'}
                    </option>
                    <option value="IR">
                      {langue === 'english' ? 'Iran' : 'Irã'}
                    </option>
                    <option value="IQ">
                      {langue === 'english' ? 'Iraq' : 'Iraque'}
                    </option>
                    <option value="IE">
                      {langue === 'english' ? 'Ireland' : 'Irlanda'}
                    </option>
                    <option value="IM">
                      {langue === 'english' ? 'Isle of Man' : 'Ilha de Man'}
                    </option>
                    <option value="IL">
                      {langue === 'english' ? 'Israel' : 'Israel'}
                    </option>
                    <option value="IT">
                      {langue === 'english' ? 'Italy' : 'Itália'}
                    </option>
                    <option value="CI">
                      {langue === 'english' ? 'Ivory Coast' : 'Costa do Marfim'}
                    </option>
                    <option value="JM">
                      {langue === 'english' ? 'Jamaica' : 'Jamaica'}
                    </option>
                    <option value="JP">
                      {langue === 'english' ? 'Japan' : 'Japão'}
                    </option>
                    <option value="JE">
                      {langue === 'english' ? 'Jersey' : 'Jersey'}
                    </option>
                    <option value="JO">
                      {langue === 'english' ? 'Jordan' : 'Jordânia'}
                    </option>
                    <option value="KZ">
                      {langue === 'english' ? 'Kazakhstan' : 'Cazaquistão'}
                    </option>
                    <option value="KE">
                      {langue === 'english' ? 'Kenya' : 'Quênia'}
                    </option>
                    <option value="KI">
                      {langue === 'english' ? 'Kiribati' : 'Kiribati'}
                    </option>
                    <option value="KW">
                      {langue === 'english' ? 'Kuwait' : 'Kuwait'}
                    </option>
                    <option value="KG">
                      {langue === 'english' ? 'Kyrgyzstan' : 'Quirguistão'}
                    </option>
                    <option value="LA">
                      {langue === 'english' ? 'Laos' : 'Laos'}
                    </option>
                    <option value="LV">
                      {langue === 'english' ? 'Latvia' : 'Letônia'}
                    </option>
                    <option value="LB">
                      {langue === 'english' ? 'Lebanon' : 'Líbano'}
                    </option>
                    <option value="LS">
                      {langue === 'english' ? 'Lesotho' : 'Lesoto'}
                    </option>
                    <option value="LR">
                      {langue === 'english' ? 'Liberia' : 'Libéria'}
                    </option>
                    <option value="LY">
                      {langue === 'english' ? 'Libya' : 'Líbia'}
                    </option>
                    <option value="LI">
                      {langue === 'english' ? 'Liechtenstein' : 'Liechtenstein'}
                    </option>
                    <option value="LT">
                      {langue === 'english' ? 'Lithuania' : 'Lituânia'}
                    </option>
                    <option value="LU">
                      {langue === 'english' ? 'Luxembourg' : 'Luxemburgo'}
                    </option>
                    <option value="MO">
                      {langue === 'english'
                        ? 'Macao S.A.R., China'
                        : 'Macau, China'}
                    </option>
                    <option value="MK">
                      {langue === 'english' ? 'Macedonia' : 'Macedônia'}
                    </option>
                    <option value="MG">
                      {langue === 'english' ? 'Madagascar' : 'Madagáscar'}
                    </option>
                    <option value="MW">
                      {langue === 'english' ? 'Malawi' : 'Malawi'}
                    </option>
                    <option value="MY">
                      {langue === 'english' ? 'Malaysia' : 'Malásia'}
                    </option>
                    <option value="MV">
                      {langue === 'english' ? 'Maldives' : 'Maldivas'}
                    </option>
                    <option value="ML">
                      {langue === 'english' ? 'Mali' : 'Mali'}
                    </option>
                    <option value="MT">
                      {langue === 'english' ? 'Malta' : 'Malta'}
                    </option>
                    <option value="MH">
                      {langue === 'english'
                        ? 'Marshall Islands'
                        : 'Ilhas Marshall'}
                    </option>
                    <option value="MQ">
                      {langue === 'english' ? 'Martinique' : 'Martinica'}
                    </option>
                    <option value="MR">
                      {langue === 'english' ? 'Mauritania' : 'Mauritânia'}
                    </option>
                    <option value="MU">
                      {langue === 'english' ? 'Mauritius' : 'Maurício'}
                    </option>
                    <option value="YT">
                      {langue === 'english' ? 'Mayotte' : 'Mayotte'}
                    </option>
                    <option value="MX">
                      {langue === 'english' ? 'Mexico' : 'México'}
                    </option>
                    <option value="FM">
                      {langue === 'english' ? 'Micronesia' : 'Micronésia'}
                    </option>
                    <option value="MD">
                      {langue === 'english' ? 'Moldova' : 'Moldávia'}
                    </option>
                    <option value="MC">
                      {langue === 'english' ? 'Monaco' : 'Mônaco'}
                    </option>
                    <option value="MN">
                      {langue === 'english' ? 'Mongolia' : 'Mongólia'}
                    </option>
                    <option value="ME">
                      {langue === 'english' ? 'Montenegro' : 'Montenegro'}
                    </option>
                    <option value="MS">
                      {langue === 'english' ? 'Montserrat' : 'Montserrat'}
                    </option>
                    <option value="MA">
                      {langue === 'english' ? 'Morocco' : 'Marrocos'}
                    </option>
                    <option value="MZ">
                      {langue === 'english' ? 'Mozambique' : 'Moçambique'}
                    </option>
                    <option value="MM">
                      {langue === 'english' ? 'Myanmar' : 'Myanmar (Birmânia)'}
                    </option>
                    <option value="NA">
                      {langue === 'english' ? 'Namibia' : 'Namíbia'}
                    </option>
                    <option value="NR">
                      {langue === 'english' ? 'Nauru' : 'Nauru'}
                    </option>
                    <option value="NP">
                      {langue === 'english' ? 'Nepal' : 'Nepal'}
                    </option>
                    <option value="NL">
                      {langue === 'english' ? 'Netherlands' : 'Países Baixos'}
                    </option>
                    <option value="NC">
                      {langue === 'english'
                        ? 'New Caledonia'
                        : 'Nova Caledônia'}
                    </option>
                    <option value="NZ">
                      {langue === 'english' ? 'New Zealand' : 'Nova Zelândia'}
                    </option>
                    <option value="NI">
                      {langue === 'english' ? 'Nicaragua' : 'Nicarágua'}
                    </option>
                    <option value="NE">
                      {langue === 'english' ? 'Niger' : 'Níger'}
                    </option>
                    <option value="NG">
                      {langue === 'english' ? 'Nigeria' : 'Nigéria'}
                    </option>
                    <option value="NU">
                      {langue === 'english' ? 'Niue' : 'Niue'}
                    </option>
                    <option value="NF">
                      {langue === 'english' ? 'Norfolk Island' : 'Ilha Norfolk'}
                    </option>
                    <option value="KP">
                      {langue === 'english' ? 'North Korea' : 'Coreia do Norte'}
                    </option>
                    <option value="NO">
                      {langue === 'english' ? 'Norway' : 'Noruega'}
                    </option>
                    <option value="OM">
                      {langue === 'english' ? 'Oman' : 'Omã'}
                    </option>
                    <option value="PK">
                      {langue === 'english' ? 'Pakistan' : 'Paquistão'}
                    </option>
                    <option value="PS">
                      {langue === 'english'
                        ? 'Palestinian Territory'
                        : 'Território Palestino'}
                    </option>
                    <option value="PA">
                      {langue === 'english' ? 'Panama' : 'Panamá'}
                    </option>
                    <option value="PG">
                      {langue === 'english'
                        ? 'Papua New Guinea'
                        : 'Papua-Nova Guiné'}
                    </option>
                    <option value="PY">
                      {langue === 'english' ? 'Paraguay' : 'Paraguai'}
                    </option>
                    <option value="PE">
                      {langue === 'english' ? 'Peru' : 'Peru'}
                    </option>
                    <option value="PH">
                      {langue === 'english' ? 'Philippines' : 'Filipinas'}
                    </option>
                    <option value="PN">
                      {langue === 'english' ? 'Pitcairn' : 'Pitcairn'}
                    </option>
                    <option value="PL">
                      {langue === 'english' ? 'Poland' : 'Polônia'}
                    </option>
                    <option value="PT">
                      {langue === 'english' ? 'Portugal' : 'Portugal'}
                    </option>
                    <option value="QA">
                      {langue === 'english' ? 'Qatar' : 'Catar'}
                    </option>
                    <option value="RE">
                      {langue === 'english' ? 'Reunion' : 'Reunião'}
                    </option>
                    <option value="RO">
                      {langue === 'english' ? 'Romania' : 'Romênia'}
                    </option>
                    <option value="RU">
                      {langue === 'english' ? 'Russia' : 'Rússia'}
                    </option>
                    <option value="RW">
                      {langue === 'english' ? 'Rwanda' : 'Ruanda'}
                    </option>
                    <option value="BL">
                      {langue === 'english'
                        ? 'Saint Barthélemy'
                        : 'São Bartolomeu'}
                    </option>
                    <option value="SH">
                      {langue === 'english' ? 'Saint Helena' : 'Santa Helena'}
                    </option>
                    <option value="KN">
                      {langue === 'english'
                        ? 'Saint Kitts and Nevis'
                        : 'São Cristóvão e Nevis'}
                    </option>
                    <option value="LC">
                      {langue === 'english' ? 'Saint Lucia' : 'Santa Lúcia'}
                    </option>
                    <option value="MF">
                      {langue === 'english'
                        ? 'Saint Martin (French part)'
                        : 'São Martinho (parte francesa)'}
                    </option>
                    <option value="SX">
                      {langue === 'english'
                        ? 'Saint Martin (Dutch part)'
                        : 'São Martinho (parte holandesa)'}
                    </option>
                    <option value="PM">
                      {langue === 'english'
                        ? 'Saint Pierre and Miquelon'
                        : 'Saint-Pierre e Miquelon'}
                    </option>
                    <option value="VC">
                      {langue === 'english'
                        ? 'Saint Vincent and the Grenadines'
                        : 'São Vicente e Granadinas'}
                    </option>
                    <option value="WS">
                      {langue === 'english' ? 'Samoa' : 'Samoa'}
                    </option>
                    <option value="SM">
                      {langue === 'english' ? 'San Marino' : 'San Marino'}
                    </option>
                    <option value="ST">
                      {langue === 'english'
                        ? 'São Tomé and Príncipe'
                        : 'São Tomé e Príncipe'}
                    </option>
                    <option value="SA">
                      {langue === 'english' ? 'Saudi Arabia' : 'Arábia Saudita'}
                    </option>
                    <option value="SN">
                      {langue === 'english' ? 'Senegal' : 'Senegal'}
                    </option>
                    <option value="RS">
                      {langue === 'english' ? 'Serbia' : 'Sérvia'}
                    </option>
                    <option value="SC">
                      {langue === 'english' ? 'Seychelles' : 'Seychelles'}
                    </option>
                    <option value="SL">
                      {langue === 'english' ? 'Sierra Leone' : 'Serra Leoa'}
                    </option>
                    <option value="SG">
                      {langue === 'english' ? 'Singapore' : 'Singapura'}
                    </option>
                    <option value="SK">
                      {langue === 'english' ? 'Slovakia' : 'Eslováquia'}
                    </option>
                    <option value="SI">
                      {langue === 'english' ? 'Slovenia' : 'Eslovênia'}
                    </option>
                    <option value="SB">
                      {langue === 'english'
                        ? 'Solomon Islands'
                        : 'Ilhas Salomão'}
                    </option>
                    <option value="SO">
                      {langue === 'english' ? 'Somalia' : 'Somália'}
                    </option>
                    <option value="ZA">
                      {langue === 'english' ? 'South Africa' : 'África do Sul'}
                    </option>
                    <option value="GS">
                      {langue === 'english'
                        ? 'South Georgia/Sandwich Islands'
                        : 'Geórgia do Sul e Ilhas Sandwich do Sul'}
                    </option>
                    <option value="KR">
                      {langue === 'english' ? 'South Korea' : 'Coreia do Sul'}
                    </option>
                    <option value="SS">
                      {langue === 'english' ? 'South Sudan' : 'Sudão do Sul'}
                    </option>
                    <option value="ES">
                      {langue === 'english' ? 'Spain' : 'Espanha'}
                    </option>
                    <option value="LK">
                      {langue === 'english' ? 'Sri Lanka' : 'Sri Lanka'}
                    </option>
                    <option value="SD">
                      {langue === 'english' ? 'Sudan' : 'Sudão'}
                    </option>
                    <option value="SR">
                      {langue === 'english' ? 'Suriname' : 'Suriname'}
                    </option>
                    <option value="SJ">
                      {langue === 'english'
                        ? 'Svalbard and Jan Mayen'
                        : 'Svalbard e Jan Mayen'}
                    </option>
                    <option value="SZ">
                      {langue === 'english' ? 'Swaziland' : 'Suazilândia'}
                    </option>
                    <option value="SE">
                      {langue === 'english' ? 'Sweden' : 'Suécia'}
                    </option>
                    <option value="CH">
                      {langue === 'english' ? 'Switzerland' : 'Suíça'}
                    </option>
                    <option value="SY">
                      {langue === 'english' ? 'Syria' : 'Síria'}
                    </option>
                    <option value="TW">
                      {langue === 'english' ? 'Taiwan' : 'Taiwan'}
                    </option>
                    <option value="TJ">
                      {langue === 'english' ? 'Tajikistan' : 'Tajiquistão'}
                    </option>
                    <option value="TZ">
                      {langue === 'english' ? 'Tanzania' : 'Tanzânia'}
                    </option>
                    <option value="TH">
                      {langue === 'english' ? 'Thailand' : 'Tailândia'}
                    </option>
                    <option value="TL">
                      {langue === 'english' ? 'Timor-Leste' : 'Timor-Leste'}
                    </option>
                    <option value="TG">
                      {langue === 'english' ? 'Togo' : 'Togo'}
                    </option>
                    <option value="TK">
                      {langue === 'english' ? 'Tokelau' : 'Tokelau'}
                    </option>
                    <option value="TO">
                      {langue === 'english' ? 'Tonga' : 'Tonga'}
                    </option>
                    <option value="TT">
                      {langue === 'english'
                        ? 'Trinidad and Tobago'
                        : 'Trinidad e Tobago'}
                    </option>
                    <option value="TN">
                      {langue === 'english' ? 'Tunisia' : 'Tunísia'}
                    </option>
                    <option value="TR">
                      {langue === 'english' ? 'Turkey' : 'Turquia'}
                    </option>
                    <option value="TM">
                      {langue === 'english' ? 'Turkmenistan' : 'Turcomenistão'}
                    </option>
                    <option value="TC">
                      {langue === 'english'
                        ? 'Turks and Caicos Islands'
                        : 'Ilhas Turks e Caicos'}
                    </option>
                    <option value="TV">
                      {langue === 'english' ? 'Tuvalu' : 'Tuvalu'}
                    </option>
                    <option value="UG">
                      {langue === 'english' ? 'Uganda' : 'Uganda'}
                    </option>
                    <option value="UA">
                      {langue === 'english' ? 'Ukraine' : 'Ucrânia'}
                    </option>
                    <option value="AE">
                      {langue === 'english'
                        ? 'United Arab Emirates'
                        : 'Emirados Árabes Unidos'}
                    </option>
                    <option value="GB">
                      {langue === 'english'
                        ? 'United Kingdom (UK)'
                        : 'Reino Unido (UK)'}
                    </option>
                    <option value="UM">
                      {langue === 'english'
                        ? 'United States (US) Minor Outlying Islands'
                        : 'Ilhas Menores Distantes dos Estados Unidos'}
                    </option>
                    <option value="UY">
                      {langue === 'english' ? 'Uruguay' : 'Uruguai'}
                    </option>
                    <option value="UZ">
                      {langue === 'english' ? 'Uzbekistan' : 'Uzbequistão'}
                    </option>
                    <option value="VU">
                      {langue === 'english' ? 'Vanuatu' : 'Vanuatu'}
                    </option>
                    <option value="VA">
                      {langue === 'english' ? 'Vatican' : 'Vaticano'}
                    </option>
                    <option value="VE">
                      {langue === 'english' ? 'Venezuela' : 'Venezuela'}
                    </option>
                    <option value="VN">
                      {langue === 'english' ? 'Vietnam' : 'Vietnã'}
                    </option>
                    <option value="WF">
                      {langue === 'english'
                        ? 'Wallis and Futuna'
                        : 'Wallis e Futuna'}
                    </option>
                    <option value="EH">
                      {langue === 'english'
                        ? 'Western Sahara'
                        : 'Saara Ocidental'}
                    </option>
                    <option value="YE">
                      {langue === 'english' ? 'Yemen' : 'Iémen'}
                    </option>
                    <option value="ZM">
                      {langue === 'english' ? 'Zambia' : 'Zâmbia'}
                    </option>
                    <option value="ZW">
                      {langue === 'english' ? 'Zimbabwe' : 'Zimbábue'}
                    </option>
                  </select>
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'Phone' : 'Telefone'}
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'Marital status' : 'Estado Civil'}
                  </label>
                  <select
                    type="text"
                    name="marital"
                    value={formData.marital}
                    onChange={handleInputChange}
                    className="register-input input_select"
                  >
                    <option value="" disabled>
                      {langue === 'english' ? 'Marital status' : 'Estado Civil'}
                    </option>
                    <option value="married">
                      {langue === 'english' ? 'Married' : 'Casado'}
                    </option>
                    <option value="single">
                      {langue === 'english' ? 'single' : 'Solteiro'}
                    </option>
                    <option value="divorce">
                      {langue === 'english' ? 'Divorce' : 'Divórcio'}
                    </option>
                    <option value="widower">
                      {langue === 'english' ? 'Widower' : 'Viúvo'}
                    </option>
                  </select>
                </div>
              </div>
              {/* End part 1 of register's informations. Next for part 2 */}
              <div className="register-part-2">
                <div>
                  <label className="register-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'Confirm Email' : 'Confirmar Email'}
                  </label>
                  <input
                    type="email"
                    name="confirmEmail"
                    value={formData.confirmEmail}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'Password' : 'Senha'}
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english'
                      ? 'Confirm Password'
                      : 'Confirmar Senha'}
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'Occupation' : 'Ocupação'}
                  </label>
                  <input
                    type="text"
                    name="occupation"
                    value={formData.occupation}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'City' : 'Cidade'}
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>
                <div>
                  <label className="register-label">
                    {langue === 'english' ? 'Address' : 'Endereço'}
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="register-input"
                  />
                </div>
              </div>
              {/* End part 2 of register's informations. */}
            </div>
            <h3 style={{ color: 'red' }}> {error} </h3>

            <div className="btn_container">
              <input
                type="reset"
                value={langue === 'english' ? 'Cancel' : 'Cancelar'}
                className="cancel"
              />

              <Link
                onClick={(e) => {
                  e.preventDefault()
                  registerUsers()
                }}
                to="/account_validation"
                className="connect_me"
              >
                {langue === 'english' ? 'Continue' : 'Continuar'}
              </Link>
            </div>
          </form>
          <div className="login_link">
            <a href="/login">
              {langue === 'english' ? 'To log in!' : 'Fazer login!'}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup
