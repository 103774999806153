import React, { useContext, useEffect, useState } from 'react'
import { LangueContext } from '../../utils/context'
import './footer.css'
import { useLangue } from '../../utils/hooks'

function Footer() {
  const { langue, setLangue } = useContext(LangueContext)
  const [selectedLangue, setSelectedLangue] = useState(() => {
    const savedLangue = localStorage.getItem('langue')
    return savedLangue || langue
  })

  const handleLangueChange = (event) => {
    setSelectedLangue(event.target.value)
    setLangue(event.target.value)
    localStorage.setItem('langue', event.target.value)
  }

  return (
    <div className="footerContainer">
      <div className="footerWrapper" langue={langue}>
        <div className="footer_top">
          <div className="footer_top_col_1">
            <h1 style={{ marginBottom: '15px' }}>
              {langue === 'english' ? 'Asly' : 'Asly'}
            </h1>
            <p>
              {langue === 'english'
                ? 'The company Asly Bank SAS is registered on the Single Register of Intermediaries in Insurance, Banking and Finance (ORIAS), in the category Broker in banking operations and payment services (COBSP) is subject to the ACPR, the prudential supervisory authority and resolution.'
                : langue === 'portuguais'
                ? 'A empresa Asly Bank SAS está registrada no Registro Único de Intermediários em Seguros, Bancos e Finanças (ORIAS), na categoria Corretora em operações bancárias e serviços de pagamento (COBSP) está sujeita à ACPR, autoridade prudencial de supervisão e resolução.'
                : 'Das Unternehmen Asly Bank SAS ist im einzigen Register der Vermittler im Bereich Versicherungen, Banken und Finanzen (ORIAS) eingetragen. In der Kategorie Broker für Bankgeschäfte und Zahlungsdienste (COBSP) unterliegt es der ACPR, der aufsichtsrechtlichen Behörde und Aufsichtseinrichtung.'}
            </p>
            <ion-icon name="logo-twitter"></ion-icon>
            <ion-icon name="logo-facebook"></ion-icon>
            <ion-icon name="logo-instagram"></ion-icon>
            <ion-icon name="logo-linkedin"></ion-icon>
          </div>
          <div className="footer_top_col">
            <h2 style={{ marginBottom: '15px' }}>
              {langue === 'english'
                ? 'Discover us'
                : langue === 'portuguais'
                ? 'Nosso Descobrir'
                : 'Entdecken Sie uns'}
            </h2>
            <a href="/about" className="link">
              {langue === 'english'
                ? 'About'
                : langue === 'portuguais'
                ? 'Sobre'
                : 'Über uns'}
            </a>
            <br />
            <a href="/team" className="link">
              {langue === 'english'
                ? 'Leading team'
                : langue === 'portuguais'
                ? 'Equipe Líder'
                : 'Führendes Team'}
            </a>
            <br />
            <a href="/#testimonials" className="link">
              {langue === 'english'
                ? 'Testimonials'
                : langue === 'portuguais'
                ? 'Depoimentos'
                : 'Erfahrungsberichte'}
            </a>
            <br />
          </div>
          <div className="footer_top_col">
            <h2 style={{ marginBottom: '15px' }}>
              {langue === 'english'
                ? 'Learn more'
                : langue === 'portuguais'
                ? 'Saiba mais'
                : 'Mehr erfahren'}
            </h2>
            <a href="/bank_account_info" className="link">
              {langue === 'english'
                ? 'Bank account'
                : langue === 'portuguais'
                ? 'Conta bancária'
                : 'Bankkonto'}
            </a>
            <br />
            <a href="/legal_notice" className="link">
              {langue === 'english'
                ? 'Legal Notice'
                : langue === 'portuguais'
                ? 'Aviso Legal'
                : 'Rechtliche Hinweise'}
            </a>
            <br />
            <a href="/confidentiality" className="link">
              {langue === 'english'
                ? 'Confidentiality'
                : langue === 'portuguais'
                ? 'Confidencialidade'
                : 'Vertraulichkeit'}
            </a>
            <br />
          </div>
          <div className="footer_top_col">
            <h2 style={{ marginBottom: '15px' }}>
              {langue === 'english'
                ? 'Contact us'
                : langue === 'portuguais'
                ? 'Contate-Nos'
                : 'Kontaktieren Sie uns'}
            </h2>
            <p>{`${process.env.REACT_APP_OFFICE_ADRESSE}`}</p>
            <p>
              <strong>E-mail</strong>: {`${process.env.REACT_APP_OFFICE_EMAIL}`}
            </p>
          </div>
        </div>
        <div className="langueBoxContainer">
          <select value={selectedLangue} onChange={handleLangueChange}>
            <option value="" disabled selected hidden class="text-muted ">
              Choose language
            </option>
            <option disabled value="">
              Choose language
            </option>
            <option value="deutsch">Deutsch</option>
            {/*<option value="portuguais">Português</option> */}
            <option value="english">English</option>
            {/* Ajouté l'option Allemand */}
          </select>
        </div>
      </div>
      <div className="footer_bottom">
        <p>
          {' '}
          &copy;{' '}
          {langue === 'english'
            ? 'Copyright'
            : langue === 'portuguais'
            ? 'Direitos Autorais'
            : 'Urheberrecht'}{' '}
          BMP.{' '}
          {langue === 'english'
            ? 'All rights reserved'
            : langue === 'portuguais'
            ? 'Todos os direitos reservados'
            : 'Alle Rechte vorbehalten'}
        </p>
        <p>
          {langue === 'english'
            ? 'Designed by'
            : langue === 'portuguais'
            ? 'Projetado por'
            : 'Entworfen von'}{' '}
          Credas{' '}
        </p>
      </div>
    </div>
  )
}

export default Footer
