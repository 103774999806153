import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Loader } from '../../utils/style/loader'
import { Box, Grid, TextField, Typography, Button } from '@mui/material'

// import { LoginContext } from '../../utils/context'
import { useLangue } from '../../utils/hooks'

import './loan.css'

function LoanForm() {
  const { langue } = useLangue()

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState('')

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setLoanData((prevData) => ({ ...prevData, [name]: value }))
  }
  const [loanData, setLoanData] = useState({
    object: '',
    amount: '',
    period: '',
    rate: '',
    name: '',
    email: '',
  })

  const handleLinkClicK = () => {
    const text =
      langue === 'english'
        ? "By applying for a loan here, you agree to your personal data being shared with the bank's loan department so that your application can be processed. If the request is accepted, you will be contacted via the e-mail address you provided."
        : langue === 'portuguais'
        ? 'Ao solicitar um empréstimo aqui, concorda que os seus dados pessoais sejam partilhados com o departamento de empréstimos do banco para que o seu pedido possa ser processado. Se o pedido for aceite, será contactado através do endereço eletrónico que indicou.'
        : 'Indem Sie hier einen Kredit beantragen, stimmen Sie zu, dass Ihre persönlichen Daten mit der Kreditantragsabteilung der Bank geteilt werden, damit Ihr Antrag bearbeitet werden kann. Wenn der Antrag akzeptiert wird, werden Sie über die angegebene E-Mail-Adresse kontaktiert.'

    Swal.fire({
      title:
        langue === 'english'
          ? 'Important message!'
          : langue === 'portuguais'
          ? 'Mensagem importante!'
          : 'Wichtige Nachricht!',
      text: text,
      icon: 'info', // icône de l'alerte (success, error, warning, info, question)
      confirmButtonColor: '#3085d6', // couleur du bouton de confirmation
      confirmButtonText: 'OK', // texte du bouton de confirmation
      showCancelButton: false, // affiche ou masque le bouton d'annulation
    })
  }

  // setLoanData(loanData)
  const checkEmptyData = () => {
    if (
      loanData.object === '' ||
      loanData.amount === '' ||
      loanData.period === '' ||
      loanData.name === '' ||
      loanData.email === ''
    ) {
      setIsLoading(false)
      setError(
        langue === 'english'
          ? 'No empty fields !'
          : langue === 'portuguais'
          ? 'Nenhum campo vazio !'
          : 'Keine leeren Felder!'
      )
      return false
    }
    setError('')
    return true
  }

  const trustEmail = (email) => {
    let emailRegExp = new RegExp('[a-z0-9._-]+@[a-z0-9._-]+\\.[a-z0-9._-]+')
    if (!emailRegExp.test(email)) {
      setIsLoading(false)
      setError(
        langue === 'english'
          ? 'Invalid e-amil !'
          : langue === 'portuguais'
          ? 'E-mail inválido!'
          : 'Ungültige E-Mail!'
      )
      return false
    }
    return true
  }

  // Send mail

  const handleSubmit = () => {
    if (!checkEmptyData() || !trustEmail(loanData.email)) {
      return
    }
    setIsLoading(true)
    setError('')

    fetch(`${process.env.REACT_APP_BASE_URL}/loan/loan-request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loanData),
    })
      .then((response) => {
        if (response.ok) {
          setIsLoading(false)
          navigate('/')
          setError('')
          setLoanData({
            object: '',
            amount: '',
            period: '',
            rate: '',
            name: '',
            email: '',
          })
          setSuccess(
            langue === 'english'
              ? 'Your request has been sent successfully!'
              : langue === 'portuguais'
              ? 'O seu pedido foi enviado com sucesso!'
              : 'Ihre Anfrage wurde erfolgreich gesendet!'
          )
        } else {
          setIsLoading(false)
          setError(
            langue === 'english'
              ? 'Something is wrong. Refresh the page and try again.'
              : langue === 'portuguais'
              ? 'Algo está errado. Actualize a página e tente novamente.'
              : 'Etwas ist schief gelaufen. Aktualisieren Sie die Seite und versuchen Sie es erneut.'
          )
        }
      })
      .catch((error) => {
        console.error('Error sending email:', error)
      })
  }

  return (
    <div
      className="loanFormWrapper loanAskingWrapper"
      style={{ marginTop: '77px' }}
      langue={langue}
    >
      <h1>
        {langue === 'english'
          ? 'Loan amortization table'
          : langue === 'portuguais'
          ? 'Tabela de amortização do empréstimo'
          : 'Kredit Amortisationstabelle'}
      </h1>
      <div className="loan_info">
        {langue === 'english'
          ? 'Important ! Click '
          : langue === 'portuguais'
          ? 'Importante ! Clique '
          : 'Wichtig! Klicken Sie '}
        <Link onClick={handleLinkClicK} className="alert">
          {langue === 'english'
            ? 'Here'
            : langue === 'portuguais'
            ? 'Aqui'
            : 'Hier'}
        </Link>{' '}
        {langue === 'english'
          ? 'first.'
          : langue === 'portuguais'
          ? 'primeiro.'
          : 'zuerst.'}
      </div>
      <p className="loan_info">
        {langue === 'english'
          ? 'Please complete this form carefully to apply for a loan!'
          : langue === 'portuguais'
          ? 'Por favor, preencha este formulário com cuidado para solicitar um empréstimo!'
          : 'Bitte füllen Sie dieses Formular sorgfältig aus, um einen Kredit zu beantragen!'}
      </p>
      <h3
        style={{
          color: 'green',
          fontSize: '20px',
          textAlign: 'center',
          marginTop: '10px',
          marginBottom: '-10px',
        }}
      >
        {success}
      </h3>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault()
          if (checkEmptyData(loanData)) {
            handleSubmit()
          }
        }}
        className="fromForLoan"
      >
        <Grid container spacing={2}>
          {/* Full Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              id="standard-basic"
              label={
                langue === 'english'
                  ? 'Full name'
                  : langue === 'portuguais'
                  ? 'Nome completo'
                  : 'Vollständiger Name'
              }
              variant="standard"
              name="name"
              value={loanData.name}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={6}>
            <TextField
              id="standard-basic"
              label={
                langue === 'english'
                  ? 'E-mail'
                  : langue === 'portuguais'
                  ? 'E-mail'
                  : 'E-Mail'
              }
              variant="standard"
              type="email"
              name="email"
              value={loanData.email}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          {/* Amount Borrowed */}
          <Grid item xs={12} sm={6}>
            <TextField
              id="standard-basic"
              label={
                langue === 'english'
                  ? 'Amount borrowed (euros)'
                  : langue === 'portuguais'
                  ? 'Valor emprestado (euros)'
                  : 'Geliehener Betrag (Euro)'
              }
              variant="standard"
              name="amount"
              value={loanData.amount}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          {/* Borrowing Period */}
          <Grid item xs={12} sm={6}>
            <TextField
              id="period"
              label={
                langue === 'english'
                  ? 'Borrowing period: (in months)'
                  : langue === 'portuguais'
                  ? 'Período de empréstimo: (em meses)'
                  : 'Leihfrist: (in Monaten)'
              }
              variant="standard"
              name="period"
              value={loanData.period}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          {/* Explanation */}
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              {langue === 'english'
                ? 'Why do you need a loan? Please provide us with some explanation'
                : langue === 'portuguais'
                ? 'Por que você precisa de um empréstimo? Por favor, forneça-nos alguma explicação'
                : 'Warum benötigen Sie ein Darlehen? Bitte geben Sie uns eine Erklärung'}
            </Typography>
            <TextField
              id="standard-basic"
              name="object"
              value={loanData.object}
              onChange={handleInputChange}
              multiline
              rows={4}
              fullWidth
            />
          </Grid>

          {/* Error and Loader */}
          {error && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </Grid>
          )}
          {/* {isLoading && (
            <Grid item xs={12}>
              <Typography>
                <Loader />
              </Typography>
            </Grid>
          )} */}

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                if (checkEmptyData(loanData)) {
                  handleSubmit()
                }
              }}
            >
              {isLoading ? (
                <Grid item xs={12}>
                  <Typography>
                    <Loader />
                  </Typography>
                </Grid>
              ) : langue === 'english' ? (
                'Continue'
              ) : langue === 'portuguais' ? (
                'Continuar'
              ) : (
                'Weiter'
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default LoanForm
