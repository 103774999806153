import React from 'react'
import styled from 'styled-components'
import { useLangue } from '../../utils/hooks'

import './error.css'

function Error() {
  const { langue } = useLangue()

  return (
    <div className="error_container" langue={langue}>
      <p>Oups...</p>
      <h1 className="status">404</h1>
      <p>
        {langue === 'english'
          ? "It seems that the page you're looking for doesn't exist"
          : langue === 'portuguais'
          ? 'Parece que a página que você está procurando não existe'
          : 'Es scheint, dass die Seite, die Sie suchen, nicht existiert'}
      </p>
    </div>
  )
}
export default Error
